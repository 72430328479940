<mat-card *ngIf="!isBusySaving" maincard>

    <div FileUploadHeading>
        <a style="font-size: 1.2em;line-height: 1em; font-weight: 500;">About Yourself</a>
    </div>

    <mat-accordion>
        <mat-expansion-panel style="margin-top: 10px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Questions
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon *ngIf="!validators['questions'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                    <mat-icon *ngIf="validators['questions'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline" input>
                <mat-label>Identity Document Type?</mat-label>
                <mat-select matInput #IDType required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.id_type">
                    <mat-option value="South African ID">South African ID</mat-option>
                    <mat-option value="Passport">Passport</mat-option>
                    <mat-option value="Asylum">Asylum</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Own Motorcycle?</mat-label>
                <mat-select matInput #OwnBike required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.has_own_bike">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Motorcycle License?</mat-label>
                <mat-select matInput #BikeLic required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.has_bike_lic">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Have you worked as a delivery driver before?</mat-label>
                <mat-select matInput #BikeLic required (selectionChange)="inputChanged('questions');" [(ngModel)]="hasPreviousExperience">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="hasPreviousExperience" appearance="outline" input>
                <mat-label>Who did you do deliveries for previously?</mat-label>
                <div>
                    <input matInput [(ngModel)]="ApplicantData.applicant_experience">
                </div>

            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Criminal Record?</mat-label>
                <mat-select matInput required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.has_crim_record">
                    <mat-option value="No">No</mat-option>
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="Unsure">Unsure</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Criminal Check Consent?</mat-label>
                <mat-select matInput required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.crim_check_consent">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>

            <a style="z-index: 901;" mat-list-item href="https://drive4pingo.co.za/media/legal_docs/POPIA_NOTICE_V1.pdf" target="about:blank" style="color:rgb(33, 138, 230);">POPIA Notice</a>
            <mat-form-field appearance="outline" input>
                <mat-label>Agree to POPIA Notice?</mat-label>
                <mat-select matInput required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.agree_popi">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>

            <a style="z-index: 901;" mat-list-item href="https://drive4pingo.co.za/media/legal_docs/PINGO_TERMS_23_08_2022.pdf" target="about:blank" style="color:rgb(33, 138, 230);">Terms & Conditions</a>
            <mat-form-field appearance="outline" input>
                <mat-label>Agree to Terms?</mat-label>
                <mat-select matInput required (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.agree_tos">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>

            <hr>

            <mat-form-field appearance="outline" input>
                <mat-label>Shirt Size</mat-label>
                <mat-select matInput (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.shirt_size">
                    <mat-option value="XS">XS</mat-option>
                    <mat-option value="S">S</mat-option>
                    <mat-option value="M">M</mat-option>
                    <mat-option value="L">L</mat-option>
                    <mat-option value="XL">XL</mat-option>
                    <mat-option value="XXL">XXL</mat-option>
                    <mat-option value="XXXL">XXXL</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Pants Size</mat-label>
                <mat-select matInput (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.pants_size">
                    <mat-option value="32">32</mat-option>
                    <mat-option value="34">34</mat-option>
                    <mat-option value="36">36</mat-option>
                    <mat-option value="38">38</mat-option>
                    <mat-option value="40">40</mat-option>
                    <mat-option value="42">42</mat-option>
                    <mat-option value="44">44</mat-option>
                    <mat-option value="46">46</mat-option>
                    <mat-option value="48">48</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Shoe Size</mat-label>
                <mat-select matInput (selectionChange)="inputChanged('questions');" [(ngModel)]="ApplicantData.shoe_size">
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                    <mat-option value="11">11</mat-option>
                    <mat-option value="12">12</mat-option>
                    <mat-option value="13">13</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>


        <mat-expansion-panel *ngIf="validators['questions'].is_valid" style="margin-top: 10px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Personal Info
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon *ngIf="!validators['personal_info'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                    <mat-icon *ngIf="validators['personal_info'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>


            <mat-form-field appearance="outline" input>
                <mat-label>Name</mat-label>
                <div>
                    <input matInput required (keyup)="inputChanged('personal_info');" [(ngModel)]="ApplicantData.name">
                </div>
                <mat-error>Please Provide a Valid Name</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Surname</mat-label>
                <div>
                    <input matInput required (keyup)="inputChanged('personal_info');" [(ngModel)]="ApplicantData.surname">
                </div>
                <mat-error>Please Provide a Valid Surname</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Date Of Birth</mat-label>
                <div>
                    <input matInput readonly [matDatepicker]="picker" required (dateChange)="inputChanged('personal_info');" [(ngModel)]="ApplicantData.date_of_birth">
                </div>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <p *ngIf="!is18OrOlder" style="color:tomato;">You must be 18 or older to apply!</p>

        </mat-expansion-panel>



        <mat-expansion-panel *ngIf="validators['personal_info'].is_valid" style="margin-top: 10px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Contact Info
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon *ngIf="!validators['contact_info'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                    <mat-icon *ngIf="validators['contact_info'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline" input>
                <mat-label>Telephone</mat-label>
                <div>
                    <input type="tel" matInput required (keyup)="inputChanged('contact_info');" [(ngModel)]="ApplicantData.phone">
                </div>
                <mat-error>Please Provide a Valid Telephone</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Email</mat-label>
                <div>
                    <input matInput type="email" required (keyup)="inputChanged('contact_info');" [(ngModel)]="ApplicantData.email">
                </div>
                <mat-error>Please Provide a Valid Email</mat-error>
            </mat-form-field>


        </mat-expansion-panel>


        <mat-expansion-panel *ngIf="validators['contact_info'].is_valid" style="margin-top: 10px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Location Info
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon *ngIf="!validators['location_info'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                    <mat-icon *ngIf="validators['location_info'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline" input>
                <mat-label>Street</mat-label>
                <div>
                    <input matInput="street" required (keyup)="inputChanged('location_info');" [(ngModel)]="ApplicantData.street">
                </div>
                <mat-error>Please Provide a Valid Street</mat-error>
            </mat-form-field>


            <mat-form-field appearance="outline" input>
                <mat-label>City</mat-label>
                <div>
                    <input matInput="city" required (keyup)="inputChanged('location_info');" [(ngModel)]="ApplicantData.city">
                </div>
                <mat-error>Please Provide a Valid City</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" input>
                <mat-label>Province</mat-label>
                <mat-select matInput required (selectionChange)="inputChanged('location_info');" [(ngModel)]="ApplicantData.province">
                    <!-- [(ngModel)]="$seller.data.area"> -->
                    <mat-option *ngFor="let province of provinces"
                                [value]="province">{{province}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field appearance="outline" input>
                <mat-label>Postal Code</mat-label>
                <div>
                    <input type="number" matInput="postal_code" required (keyup)="inputChanged('location_info');" [(ngModel)]="ApplicantData.postal_code">
                </div>
                <mat-error>Please Provide a Valid Postal Code</mat-error>
            </mat-form-field>


        </mat-expansion-panel>



        <!-- !!!DOCUMENTS!!! -->



        <div *ngIf="validators['location_info'].is_valid">
            <mat-progress-bar mode="determinate" value="100" style="margin-top: 20px;"></mat-progress-bar>

            <div style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                <a style="font-size: 1.2em;line-height: 1em; font-weight: 500;">Uploads/Snaps of Documents</a>
            </div>


            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="IDType.value === 'South African ID'" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        SA ID Document
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['id'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['id'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-form-field appearance="outline" input>
                    <mat-label>ID Number</mat-label>
                    <div>
                        <input matInput type="number" placeholder="Your RSA ID Number" required
                               (keyup)="uploadInputChanged('id');" [(ngModel)]="ApplicantData.id_passport_number">
                    </div>
                    <mat-error>Please Provide a Valid ID Number</mat-error>
                </mat-form-field>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your RSA ID</a>
                </div>

                <div photoDiv>
                    <img #IDDoc id="imgFront" src="" [style]="(IDDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="IDDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="IDDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="IDDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="IDDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #IDDocUpload style="display: none;" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.ID, IDDoc, IDDocUpload, 'id')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="IDType.value === 'Passport'" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Passport
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['id'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['id'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-form-field appearance="outline" input>
                    <mat-label>Passport Number</mat-label>
                    <div>
                        <input matInput placeholder="Your Passport Number" required
                               (keyup)="uploadInputChanged('id');" [(ngModel)]="ApplicantData.id_passport_number">
                    </div>
                    <mat-error>Please Provide a Valid Passport Number</mat-error>
                </mat-form-field>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Passport</a>
                </div>

                <div photoDiv>
                    <img #PassportDoc id="imgFront" src="" [style]="(PassportDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="PassportDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="PassportDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="PassportDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="PassportDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #PassportDocUpload style="display: none;" type="file" ctype="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Passport, PassportDoc, PassportDocUpload, 'id')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="IDType.value === 'Passport' && fileValidators['id'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Work Permit
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['work_permit'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['work_permit'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>

                </mat-expansion-panel-header>

                <mat-form-field appearance="outline" input>
                    <mat-label>Work Permit Expiry Date</mat-label>
                    <div>
                        <input matInput readonly [matDatepicker]="picker" required
                               (dateChange)="uploadInputChanged('work_permit');" [(ngModel)]="ApplicantData.work_permit_expiry_date"
                               (keyup)="uploadInputChanged('work_permit');" [(ngModel)]="ApplicantData.work_permit_expiry_date">
                    </div>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Work Permit</a>
                </div>

                <div photoDiv>
                    <img #WorkPermitDoc id="imgFront" src="" [style]="(WorkPermitDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="WorkPermitDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="WorkPermitDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="WorkPermitDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="WorkPermitDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #WorkPermitDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Workers_Permit, WorkPermitDoc, WorkPermitDocUpload, 'work_permit')">
            </mat-expansion-panel>






            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="IDType.value === 'Asylum'" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Asylum
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['id'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['id'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-form-field appearance="outline" input>
                    <mat-label>Asylum Number</mat-label>
                    <div>
                        <input matInput placeholder="Your Asylum Number" required
                               (keyup)="uploadInputChanged('id');" [(ngModel)]="ApplicantData.id_passport_number">
                    </div>
                    <mat-error>Please Provide a Valid Asylum Number</mat-error>
                </mat-form-field>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Asylum document</a>
                </div>

                <div photoDiv>
                    <img #AssylumDoc id="imgFront" src="" [style]="(AssylumDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="AssylumDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="AssylumDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="AssylumDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="AssylumDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #AssylumDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Assylum, AssylumDoc, AssylumDocUpload, 'id')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="fileValidators['id'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Selfie
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['selfie'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['selfie'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of Yourself</a>
                </div>

                <div photoDiv>
                    <img #SelfieDoc id="imgFront" src="" [style]="(SelfieDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="SelfieDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="SelfieDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="SelfieDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="SelfieDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #SelfieDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Selfie, SelfieDoc, SelfieDocUpload, 'selfie')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="fileValidators['selfie'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Proof of Address
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['proof_of_address'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['proof_of_address'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of Proof of Address</a>
                </div>

                <div photoDiv>
                    <img #LocProofDoc id="imgFront" src="" [style]="(LocProofDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="LocProofDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="LocProofDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="LocProofDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="LocProofDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #LocProofDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Proof_of_Residence, LocProofDoc, LocProofDocUpload, 'proof_of_address')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="BikeLic.value === 'Yes' && fileValidators['proof_of_address'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Bike License
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['bike_license'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['bike_license'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-form-field appearance="outline" input>
                    <mat-label>Bike License Expiry Date</mat-label>
                    <div>
                        <input matInput readonly [matDatepicker]="picker" required
                               (dateChange)="uploadInputChanged('bike_license');" [(ngModel)]="ApplicantData.bike_license_expiring_date">
                    </div>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <p *ngIf="!isBikeLicenseValid" style="color:tomato;">Bike license must be valid for 30 days or more!</p>
                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Bike License</a>
                </div>

                <div photoDiv>
                    <img #BikeLicDoc id="imgFront" src="" [style]="(BikeLicDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="BikeLicDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="BikeLicDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="BikeLicDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="BikeLicDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #BikeLicDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Motor_Bike_License, BikeLicDoc, BikeLicDocUpload, 'bike_license')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="OwnBike.value === 'Yes' && fileValidators['bike_license'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Bike Numberplate
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['bike_numberplate'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['bike_numberplate'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Bike Numberplate</a>
                </div>

                <div photoDiv>
                    <img #BikeNumberplateDoc id="imgFront" src="" [style]="(BikeNumberplateDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="BikeNumberplateDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="BikeNumberplateDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="BikeNumberplateDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="BikeNumberplateDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #BikeNumberplateDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.MotorBike_Number_Plate, BikeNumberplateDoc, BikeNumberplateDocUpload, 'bike_numberplate')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="OwnBike.value === 'Yes' && fileValidators['bike_numberplate'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Bike Disc
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['bike_disc'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['bike_disc'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Bike Disc</a>
                </div>

                <div photoDiv>
                    <img #BikeDiscDoc id="imgFront" src="" [style]="(BikeDiscDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="BikeDiscDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="BikeDiscDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="BikeDiscDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="BikeDiscDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #BikeDiscDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Motorbike_License_Disc, BikeDiscDoc, BikeDiscDocUpload, 'bike_disc')">
            </mat-expansion-panel>





            <!-- !!!!!! -->
            <mat-expansion-panel *ngIf="OwnBike.value === 'Yes' && fileValidators['bike_disc'].is_valid" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Bike Photo
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!fileValidators['bike_photo'].is_valid" color="warn" style="position:absolute; right:40px;">question_mark</mat-icon>
                        <mat-icon *ngIf="fileValidators['bike_photo'].is_valid" style="position:absolute; right:40px; color: greenyellow;">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div FileUploadHeading>
                    <a FileUploadHeadingText>Clear photo of your Bike</a>
                </div>

                <div photoDiv>
                    <img #BikePhotoDoc id="imgFront" src="" [style]="(BikePhotoDoc.getAttribute('src') == '')  ? 'display:none':'display:inline'" photoImg>
                    <div uploadDiv>
                        <button *ngIf="BikePhotoDoc.getAttribute('src') == ''" mat-raised-button color="primary" (click)="BikePhotoDocUpload.click();">
                            <mat-icon>add_a_file</mat-icon>
                            Add Photo
                        </button>
                        <button *ngIf="BikePhotoDoc.getAttribute('src') != ''" mat-raised-button color="primary" (click)="BikePhotoDocUpload.click();">
                            <mat-icon>find_replace</mat-icon>
                            Replace Photo
                        </button>
                    </div>
                </div>
                <input #BikePhotoDocUpload style="display: none;" type="file" type="file" accept="image/*" capture="camera"
                       (change)="collectFile($event, docType.Bike_Photo, BikePhotoDoc, BikePhotoDocUpload, 'bike_photo')">
            </mat-expansion-panel>


        </div>
    </mat-accordion>

    <mat-progress-bar mode="determinate" value="100" style="margin-top: 20px;"></mat-progress-bar>


    <div *ngIf="!showConfirmCancleApplication">


        <div *ngIf="!ApplicationValid" style="display: flex; flex-direction: row; justify-content: space-evenly; ">

            <button mat-raised-button color="primary" style="margin-top: 20px; width: 60%; "
                    (click)="showConfirmCancleApplication=true">
                Cancel Application
            </button>

        </div>

        <div *ngIf="ApplicationValid">

            <div FileUploadHeading>
                <a FileUploadHeadingText style="text-align: center; margin-top: 15px;">You are DONE! We have the required Info!</a>
            </div>

            <div style="display: flex; flex-direction: row; justify-content: space-evenly; ">

                <button mat-raised-button color="primary" style="margin-top: 20px; width: 45%; "
                        (click)="showConfirmCancleApplication=true">
                    Cancel
                </button>

                <button mat-raised-button color="primary" style="margin-top: 20px; float: right; width: 45%;" color="accent"
                        (click)="submitApplication();">
                    Submit It
                </button>

            </div>
        </div>

    </div>





    <div *ngIf="showConfirmCancleApplication">

        <div FileUploadHeading>
            <a FileUploadHeadingText style="text-align: center; margin-top: 15px;">Cancel Application? Your entries will be lost!</a>
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-evenly; ">

            <button mat-raised-button color="primary" routerLink="" style="margin-top: 20px; width: 45%; ">
                Leave
            </button>

            <button mat-raised-button style="margin-top: 20px; float: right; width: 45%;" color="accent"
                    (click)="showConfirmCancleApplication=false">
                Continue
            </button>

        </div>

    </div>


</mat-card>


<mat-card *ngIf="isBusySaving" maincard>

    <div FileUploadHeading>
        <a FileUploadHeadingText style="text-align: center; margin-top: 1px; font-size: 2em;">Now Saving!</a>
    </div>
    <div FileUploadHeading>
        <a FileUploadHeadingText style="text-align: center; margin-top: 5px; font-size: 1em;">Depending on your internet speed it might take a moment to upload your data, please be patient!</a>
    </div>


    <div FileUploadHeading>
        <a FileUploadHeadingText style="text-align: center; margin-top: 20px; font-size: 0.8em;">
            {{curTotalProgressText}}
        </a>
    </div>

    <div FileUploadHeading>
        <a FileUploadHeadingText style="text-align: center; margin-top: 5px; font-size: 0.8em;">
            {{curUploadText}}
        </a>
    </div>

    <mat-progress-bar mode="determinate" [value]="curUploadingFileProgress"></mat-progress-bar>






    <!-- <mat-progress-bar mode="determinate" min="0" [max]="totalFileCount" [value]="totalFilesUploaded" ></mat-progress-bar> -->


    <div *ngIf="!ApplicationSubmitted" style="display: flex; flex-direction: row; justify-content: space-evenly; ">

        <button mat-raised-button color="primary" style="margin-top: 20px; width: 45%; "
                (click)="isBusySaving=false">
            Cancel
        </button>

    </div>

    <div *ngIf="ApplicationSubmitted">

        <div FileUploadHeading>
            <a FileUploadHeadingText style="text-align: center; margin-top: 1px; font-size: 2em;">Success!</a>
        </div>
        <div FileUploadHeading>
            <a FileUploadHeadingText style="text-align: center; margin-top: 5px; font-size: 1em;">Your application has been submitted, we will be in contact soon!</a>
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
            <button mat-raised-button color="accent" style="margin-top: 20px; float: right; width: 45%;"
                    routerLink="">
                Done!
            </button>
        </div>

    </div>

</mat-card>


<canvas id="canvas"></canvas>





<!-- [disabled]="!name.valid || !surname.valid || !tel.valid || !email.valid || !city.valid || !dob.valid || !lc.valid || !lce.valid" -->


<!-- 
<mat-card tc m10 space50>

    <div tc thb>Please Upload The Following Documents</div>

    <hr>

    <mat-button-toggle-group name="fontStyle" style="margin-bottom: 25px;" value="citizen">
        <mat-button-toggle value="citizen">RSA Citizen</mat-button-toggle>
        <mat-button-toggle value="non_citizen">Non Citizen</mat-button-toggle>
    </mat-button-toggle-group>

    <div uploadbtnfont>

        <div m20 style="margin-bottom: 50px;">

            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file0').click()">Select CV</button>
            <h3>{{uFiles[0]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file0" #file name="file0" (change)="onUpload($event,17,0)" />


        </div>


        <div m20 style="margin-bottom: 50px;">

            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file7').click()">Select SA ID</button>
            <h3>{{uFiles[1]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file7" #file name="file7" (change)="onUpload($event,7,1)" />


        </div>

        <div m20 style="margin-bottom: 50px;">

            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file1').click()">Select Assylum / Passport</button>
            <h3>{{uFiles[6]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file1" #file name="file1" (change)="onUpload($event,9,6)" />


        </div>

        <div m20 style="margin-bottom: 50px;">

            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file2').click()">Work Permit</button>
            <h3>{{uFiles[2]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file2" #file name="file2" (change)="onUpload($event,8,2)" />


        </div>

        <div m20 style="margin-bottom: 50px;">

            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file3').click()">Bike License</button>
            <h3>{{uFiles[3]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file3" #file name="file3" (change)="onUpload($event,10,3)" />


        </div>

        <div m20 style="margin-bottom: 50px;">

            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file4').click()">Proof Of Residence</button>
            <h3>{{uFiles[4]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file4" #file name="file4" (change)="onUpload($event,12,4)" />


        </div>

        <div m20 style="margin-bottom: 50px;">


            <button class="card-button" m10 inpfld mat-raised-button color="primary" onclick="document.getElementById('file5').click()">Recent Bank Statement</button>
            <h3>{{uFiles[5]?.file_name}}</h3>
            <input type="file" style="display:none;" id="file5" #file name="file5" (change)="onUpload($event,13,5)" />


        </div>
    </div>


    <div jc>
        <div col>
            <button class="card-button" m10 inpfld btnfonts mat-raised-button color="accent">
                Back
            </button>
        </div>
        <div col>
            <button class="card-button" m10 inpfld btnfonts mat-raised-button color="accent" routerLink="" (click)="openSnackBar('thanks!','Thank you for applying, we will be in touch! ');">
                Finish
            </button>
        </div>
    </div>
</mat-card> -->