<svg class="animated" id="freepik_stories-logistics" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500" version="1.1"
        xmlns:svgjs="http://svgjs.com/svgjs">

        <g id="freepik--background-simple--inject-158" class="animable" style="transform-origin: 241.722px 226.685px;">
          <path
            d="M245.73,154.51c39.54-19.24,70.9-62.29,121.56-54.07,38,6.18,58.34,40.67,64.47,78.28,16.12,98.74-106.17,166.89-188.62,174.37C157.73,360.89,38,316.36,51.25,215.54c5.46-41.54,37.83-61.79,80.84-66.44C168.57,145.15,214.71,169.61,245.73,154.51Z"
            style="fill: primary; transform-origin: 241.722px 226.685px;" id="eloojxwy28vg8" class="animable">
          </path>
          <g id="elk48cpdgchl">
            <path
              d="M245.73,154.51c39.54-19.24,70.9-62.29,121.56-54.07,38,6.18,58.34,40.67,64.47,78.28,16.12,98.74-106.17,166.89-188.62,174.37C157.73,360.89,38,316.36,51.25,215.54c5.46-41.54,37.83-61.79,80.84-66.44C168.57,145.15,214.71,169.61,245.73,154.51Z"
              style="fill: rgb(255, 255, 255); opacity: 0.9; isolation: isolate; transform-origin: 241.722px 226.685px;"
              class="animable"></path>
          </g>
        </g>
        <g id="freepik--Shadow--inject-158" class="animable" style="transform-origin: 250px 411.67px;">
          <g id="freepik--Shadow--inject-62--inject-158" class="animable" style="transform-origin: 250px 411.67px;">
            <ellipse id="freepik--path--inject-62--inject-158" cx="250" cy="411.67" rx="193.89" ry="15.89"
              style="fill: rgb(245, 245, 245); transform-origin: 250px 411.67px;" class="animable"></ellipse>
          </g>
        </g>
        <g id="freepik--Truck--inject-158" class="animable animator-active"
          style="transform-origin: 237.13px 285.465px;">
          <path
            d="M297.68,348.92h-17c-8.85,0-16,12.83-16,28.66s7.17,28.65,16,28.65h17c8.85,0,16-12.83,16-28.65S306.53,348.92,297.68,348.92Z"
            style="fill: rgb(38, 50, 56); transform-origin: 289.18px 377.575px;" id="elz82mcke53nm" class="animable">
          </path>
          <g style="clip-path: url(&quot;#freepik--clip-path--inject-158&quot;); transform-origin: 309.18px 377.575px;"
            id="eloqjfapxjyp8" class="animable">
            <g id="eltgm0mnjbooe">
              <path
                d="M317.68,348.92h-17c-8.85,0-16,12.83-16,28.66s7.17,28.65,16,28.65h17c8.85,0,16-12.83,16-28.65S326.53,348.92,317.68,348.92Z"
                style="fill: rgb(255, 255, 255); opacity: 0.05; transform-origin: 309.18px 377.575px;" class="animable">
              </path>
            </g>
          </g>
          <polygon points="435.39 355.06 137.7 354.85 137.7 164.7 435.39 164.7 435.39 355.06"
            style="fill: rgb(64, 123, 255); transform-origin: 286.545px 259.88px;" id="elkelikhuqjb" class="animable">
          </polygon>
          <g id="el3iqymsqcar">
            <rect x="233.63" y="164.7" width="201.76" height="190.35"
              style="fill: rgb(38, 50, 56); opacity: 0.3; isolation: isolate; transform-origin: 334.51px 259.875px;"
              class="animable"></rect>
          </g>
          <g id="elgu4fkmvulnt">
            <rect x="137.7" y="164.7" width="95.93" height="194.11"
              style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 185.665px 261.755px;"
              class="animable"></rect>
          </g>
          <g id="el8r1hkfui5qf">
            <line x1="138.42" y1="173.54" x2="435.39" y2="173.54"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 173.54px;"
              class="animable"></line>
          </g>
          <g id="eln587c65jfr8">
            <line x1="138.42" y1="181.77" x2="435.39" y2="181.77"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 181.77px;"
              class="animable"></line>
          </g>
          <g id="elmp15q4r193">
            <line x1="138.42" y1="189.99" x2="435.39" y2="189.99"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 189.99px;"
              class="animable"></line>
          </g>
          <g id="elzuopmncwh9">
            <line x1="138.42" y1="198.21" x2="435.39" y2="198.21"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 198.21px;"
              class="animable"></line>
          </g>
          <g id="el1sk0ff0ydog">
            <line x1="138.42" y1="206.44" x2="435.39" y2="206.44"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 206.44px;"
              class="animable"></line>
          </g>
          <g id="elymj0hlf7jrk">
            <line x1="138.42" y1="214.66" x2="435.39" y2="214.66"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 214.66px;"
              class="animable"></line>
          </g>
          <g id="elivg9o6g7q1r">
            <line x1="138.42" y1="222.88" x2="435.39" y2="222.88"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 222.88px;"
              class="animable"></line>
          </g>
          <g id="el8x81sxpfcre">
            <line x1="138.42" y1="231.1" x2="435.39" y2="231.1"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 231.1px;"
              class="animable"></line>
          </g>
          <g id="ell5vg37fiuh">
            <line x1="138.42" y1="239.33" x2="435.39" y2="239.33"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 239.33px;"
              class="animable"></line>
          </g>
          <g id="el19gmkuy9p8mh">
            <line x1="138.42" y1="247.55" x2="435.39" y2="247.55"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 247.55px;"
              class="animable"></line>
          </g>
          <g id="el9qxwcpm00sk">
            <line x1="138.42" y1="255.77" x2="435.39" y2="255.77"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 255.77px;"
              class="animable"></line>
          </g>
          <g id="el0gyonobt4cds">
            <line x1="138.42" y1="264" x2="435.39" y2="264"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 264px;"
              class="animable"></line>
          </g>
          <g id="elxvh93928hh">
            <line x1="138.42" y1="272.22" x2="435.39" y2="272.22"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 272.22px;"
              class="animable"></line>
          </g>
          <g id="el5qqkke0dvb4">
            <line x1="138.42" y1="280.44" x2="435.39" y2="280.44"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 280.44px;"
              class="animable"></line>
          </g>
          <g id="elcle016iaxfa">
            <line x1="138.42" y1="288.66" x2="435.39" y2="288.66"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 288.66px;"
              class="animable"></line>
          </g>
          <g id="eldnn198mg1u">
            <line x1="138.42" y1="296.89" x2="435.39" y2="296.89"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 296.89px;"
              class="animable"></line>
          </g>
          <g id="el3t6mr4za2dh">
            <line x1="138.42" y1="305.11" x2="435.39" y2="305.11"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 305.11px;"
              class="animable"></line>
          </g>
          <g id="els9idnwdtj1f">
            <line x1="138.42" y1="313.33" x2="435.39" y2="313.33"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 313.33px;"
              class="animable"></line>
          </g>
          <g id="elkwm4z2r7tpo">
            <line x1="138.42" y1="321.56" x2="435.39" y2="321.56"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 321.56px;"
              class="animable"></line>
          </g>
          <g id="eloht2yb28p9p">
            <line x1="138.42" y1="329.78" x2="435.39" y2="329.78"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 329.78px;"
              class="animable"></line>
          </g>
          <g id="elvcatg3obda">
            <line x1="138.42" y1="338" x2="435.39" y2="338"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 338px;"
              class="animable"></line>
          </g>
          <g id="elakgye40kzjr">
            <line x1="138.42" y1="346.23" x2="435.39" y2="346.23"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; opacity: 0.1; transform-origin: 286.905px 346.23px;"
              class="animable"></line>
          </g>
          <path
            d="M83.07,352.66H67.34c-8.2,0-14.84,11.88-14.84,26.54s6.64,26.53,14.84,26.53H83.07c8.2,0,14.84-11.88,14.84-26.53S91.27,352.66,83.07,352.66Z"
            style="fill: rgb(38, 50, 56); transform-origin: 75.205px 379.195px;" id="el1zccelvwdj7" class="animable">
          </path>
          <g id="elhn693flzqca">
            <path
              d="M103.08,352.66H87.34c-8.2,0-14.84,11.88-14.84,26.54s6.64,26.53,14.84,26.53h15.74c8.19,0,14.84-11.88,14.84-26.53S111.27,352.66,103.08,352.66Z"
              style="fill: rgb(255, 255, 255); opacity: 0.05; transform-origin: 95.21px 379.195px;" class="animable">
            </path>
          </g>
          <path
            d="M74.32,300.83H66.88c-1.46.05-2.66-1.51-2.69-3.49a3.22,3.22,0,0,1,0-.62l2-22.47c.25-2.31,1.68-4,3.4-4.11H77.1c1.45,0,2.65,1.53,2.68,3.51q0,.3,0,.6l-2,22.47C77.48,299,76,300.77,74.32,300.83Z"
            style="fill: rgb(38, 50, 56); transform-origin: 71.9775px 285.486px;" id="elj4s97nv3dn" class="animable">
          </path>
          <path
            d="M81.76,226.61,67.41,304,223.64,365.2l-1.09-152.52-34.36-1H94.07C88.41,211.63,83.42,217.71,81.76,226.61Z"
            style="fill: rgb(64, 123, 255); transform-origin: 145.525px 288.44px;" id="el9647ok9pbx" class="animable">
          </path>
          <path d="M198,239.06c-5.83-.26-11.06,4.52-12.77,11.65l-11.82,49.52H150.66l-1.24,65.33h74.83"
            style="fill: rgb(64, 123, 255); transform-origin: 186.835px 302.305px;" id="el7ghi1tx0t58" class="animable">
          </path>
          <path
            d="M84,296.07c-2.29,0-4.14-2.94-4.14-6.56a9.74,9.74,0,0,1,.18-1.88l8.59-45.44c.72-3.77,2.92-6.35,5.41-6.34h82.18c.89,0,1.62,1.14,1.62,2.56a3.74,3.74,0,0,1-.07.75c-1.8,9.36-7.65,40.37-9.89,52.23-.52,2.78-2.14,4.68-4,4.68Z"
            style="fill: rgb(38, 50, 56); transform-origin: 128.85px 265.96px;" id="elwzlkvdtvm18" class="animable">
          </path>
          <path
            d="M159.62,316.21a17.21,17.21,0,0,1-.17,2.38l-.43,2.93-.25,1.73-1.84,12.64-3,20.55-.83,5.71H36.81l0-5.71.08-20.55L37,323.6v-5c-1-6.6,2.19-13,7.05-14.33a6.52,6.52,0,0,1,1.75-.23H150.66C155.61,304,159.62,309.48,159.62,316.21Z"
            style="fill: rgb(64, 123, 255); transform-origin: 98.215px 333.095px;" id="elvkdnizz9fia" class="animable">
          </path>
          <path
            d="M218.73,242v48.71c0,2.95-1.51,5.35-3.38,5.36H186.93c-1.87,0-3.38-2.4-3.38-5.36a8.57,8.57,0,0,1,.12-1.44l8.76-49.8c.42-2.39,1.82-4,3.38-3.91l19.66,1.08C217.29,236.75,218.73,239.12,218.73,242Z"
            style="fill: rgb(38, 50, 56); transform-origin: 201.14px 265.813px;" id="elr07r2myjk6" class="animable">
          </path>
          <g id="elwvkft8ljlu8">
            <polygon points="131.94 235.85 116.24 235.85 98.21 296.07 113.92 296.07 131.94 235.85"
              style="fill: rgb(255, 255, 255); opacity: 0.1; isolation: isolate; transform-origin: 115.075px 265.96px;"
              class="animable"></polygon>
          </g>
          <g id="elpc9ytofgvo">
            <polygon points="144.16 235.85 136.66 235.85 118.64 296.07 126.13 296.07 144.16 235.85"
              style="fill: rgb(255, 255, 255); opacity: 0.1; isolation: isolate; transform-origin: 131.4px 265.96px;"
              class="animable"></polygon>
          </g>
          <polygon points="189.75 335.89 189.75 356.44 36.83 356.44 36.91 335.89 189.75 335.89" id="eldat9hw75bt"
            class="animable" style="transform-origin: 113.29px 346.165px;"></polygon>
          <polygon
            points="168.45 335.89 154.31 335.89 128.88 350.83 128.88 356.44 189.75 356.44 189.75 335.89 168.45 335.89"
            style="fill: rgb(255, 255, 255); transform-origin: 159.315px 346.165px;" id="el83ddo5vee2s"
            class="animable"></polygon>
          <polygon points="63.19 350.83 63.19 356.44 36.83 356.44 36.91 335.89 37.77 335.89 63.19 350.83"
            style="fill: rgb(255, 255, 255); transform-origin: 50.01px 346.165px;" id="el1lb3uqxc77h" class="animable">
          </polygon>
          <g id="elcf1d4q4b1r">
            <path
              d="M223.71,365.56l-1.16-152.88-21-1c-5.24-.26-11.29,5.83-12.82,12.89L173.51,304h-5.93c-6.66,0-11.22,4.85-11.21,10l.09,51.58h67.25"
              style="fill: rgb(38, 50, 56); opacity: 0.3; isolation: isolate; transform-origin: 190.04px 288.626px;"
              class="animable"></path>
          </g>
          <path
            d="M159.23,322.24a1.35,1.35,0,0,1-.46,1,1.31,1.31,0,0,1-.9.35H37v-2.72h120.9a1.36,1.36,0,0,1,1.15.64A1.38,1.38,0,0,1,159.23,322.24Z"
            style="fill: rgb(255, 255, 255); transform-origin: 98.1154px 322.23px;" id="elhv38nnzb1fk" class="animable">
          </path>
          <polygon points="58.32 320.88 58.32 323.6 36.96 323.6 36.97 320.88 58.32 320.88"
            style="fill: rgb(38, 50, 56); transform-origin: 47.64px 322.24px;" id="elmmc4uft7dre" class="animable">
          </polygon>
          <path
            d="M159.23,322.24a1.35,1.35,0,0,1-.46,1,1.31,1.31,0,0,1-.9.35H128.55v-2.72h29.32a1.36,1.36,0,0,1,1.15.64A1.38,1.38,0,0,1,159.23,322.24Z"
            style="fill: rgb(38, 50, 56); transform-origin: 143.89px 322.23px;" id="elbhxudn7m6v8" class="animable">
          </path>
          <path d="M440.73,360v.54a5,5,0,0,1-5,5H38.53a5,5,0,0,1-5-5V360a5,5,0,0,1,5-5h397.2A5,5,0,0,1,440.73,360Z"
            style="fill: rgb(38, 50, 56); transform-origin: 237.13px 360.27px;" id="el4roz4s99h1b" class="animable">
          </path>
          <rect x="67.41" y="359.11" width="59.2" height="2.89" id="el8jefzw1ghkq" class="animable"
            style="transform-origin: 97.01px 360.555px;"></rect>
          <path d="M51.66,360.55A1.65,1.65,0,1,1,50,358.9,1.65,1.65,0,0,1,51.66,360.55Z"
            style="fill: rgb(255, 255, 255); transform-origin: 50.01px 360.55px;" id="elnd7ayfsntdd" class="animable">
          </path>
          <path d="M140.75,360.55a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,140.75,360.55Z"
            style="fill: rgb(255, 255, 255); transform-origin: 139.1px 360.55px;" id="elq7q73kqz84f" class="animable">
          </path>
          <path
            d="M188.59,376.93c0-15.91,7.21-28.81,16.11-28.81H187.61c-8.89,0-16.1,12.9-16.1,28.81s7.21,28.8,16.1,28.8H204.7C195.8,405.76,188.59,392.87,188.59,376.93Z"
            id="elv2hfgxvig2" class="animable" style="transform-origin: 188.105px 376.925px;"></path>
          <g id="elzodlbysx9t">
            <path
              d="M188.59,376.93c0-15.91,7.21-28.81,16.11-28.81H187.61c-8.89,0-16.1,12.9-16.1,28.81s7.21,28.8,16.1,28.8H204.7C195.8,405.76,188.59,392.87,188.59,376.93Z"
              style="fill: rgb(38, 50, 56); opacity: 0.7; isolation: isolate; transform-origin: 188.105px 376.925px;"
              class="animable"></path>
          </g>
          <ellipse cx="204.7" cy="376.96" rx="16.11" ry="28.81"
            style="fill: rgb(38, 50, 56); transform-origin: 204.7px 376.96px;" id="ele2fab4zweno" class="animable">
          </ellipse>
          <ellipse cx="205.99" cy="376.96" rx="11.42" ry="22.46"
            style="fill: rgb(255, 255, 255); transform-origin: 205.99px 376.96px;" id="elk85d691yfq" class="animable">
          </ellipse>
          <ellipse cx="204.95" cy="376.96" rx="10.93" ry="19.44"
            style="fill: rgb(38, 50, 56); transform-origin: 204.95px 376.96px;" id="elbym6sk10pwc" class="animable">
          </ellipse>
          <ellipse cx="202.55" cy="376.96" rx="7.98" ry="14.19" id="elih46w10bij" class="animable"
            style="transform-origin: 202.55px 376.96px;"></ellipse>
          <g id="elhlvbvojywha">
            <path d="M440.73,360v.54a5,5,0,0,1-5,5H233.63V355h202.1A5,5,0,0,1,440.73,360Z"
              style="opacity: 0.5; transform-origin: 337.18px 360.27px;" class="animable"></path>
          </g>
          <path
            d="M377.92,376.93c0-15.91,7.21-28.81,16.1-28.81H376.94c-8.9,0-16.11,12.9-16.11,28.81s7.21,28.8,16.11,28.8H394C385.13,405.76,377.92,392.87,377.92,376.93Z"
            id="eltcsjzbqgv2" class="animable" style="transform-origin: 377.425px 376.925px;"></path>
          <g id="elkku6r5zywvf">
            <path
              d="M377.92,376.93c0-15.91,7.21-28.81,16.1-28.81H376.94c-8.9,0-16.11,12.9-16.11,28.81s7.21,28.8,16.11,28.8H394C385.13,405.76,377.92,392.87,377.92,376.93Z"
              style="fill: rgb(38, 50, 56); opacity: 0.7; isolation: isolate; transform-origin: 377.425px 376.925px;"
              class="animable"></path>
          </g>
          <ellipse cx="394.02" cy="376.96" rx="16.11" ry="28.81"
            style="fill: rgb(38, 50, 56); transform-origin: 394.02px 376.96px;" id="elbldqb7d2g8t" class="animable">
          </ellipse>
          <ellipse cx="395.32" cy="376.96" rx="11.42" ry="22.46"
            style="fill: rgb(255, 255, 255); transform-origin: 395.32px 376.96px;" id="el61c09ks6534" class="animable">
          </ellipse>
          <ellipse cx="394.27" cy="376.96" rx="10.93" ry="19.44"
            style="fill: rgb(38, 50, 56); transform-origin: 394.27px 376.96px;" id="elbl752b9283a" class="animable">
          </ellipse>
          <ellipse cx="391.87" cy="376.96" rx="7.98" ry="14.19" id="elte5rob6wht" class="animable"
            style="transform-origin: 391.87px 376.96px;"></ellipse>
        </g>
        <g id="freepik--Character--inject-158" class="animable" style="transform-origin: 350.309px 303.426px;">
          <path
            d="M328.86,236c-.2,3.85-.55,7.57-1.12,11.36a63.37,63.37,0,0,1-2.56,11.53,19.5,19.5,0,0,1-1.43,3.16,9,9,0,0,1-1.44,1.86l-.28.26-.16.12-.08.07-.34.23-.67.47a36.37,36.37,0,0,1-5.34,2.83,61.16,61.16,0,0,1-10.93,3.62l-1.3-3.56c3.18-1.76,6.44-3.56,9.41-5.43a41.78,41.78,0,0,0,4.18-2.93l.44-.37.22-.19v0h0s.09-.15.2-.39a13.52,13.52,0,0,0,.72-2,44.58,44.58,0,0,0,1.07-5c.31-1.74.56-3.51.77-5.31.41-3.57.76-7.23,1-10.79Z"
            style="fill: rgb(206, 122, 99); transform-origin: 316.035px 253.525px;" id="elyu5xqmpac0o" class="animable">
          </path>
          <path d="M305.58,268l-6.14-1.9,1.15,7.43s4-.5,6-2.74Z"
            style="fill: rgb(206, 122, 99); transform-origin: 303.015px 269.815px;" id="elkj0xsqycvob" class="animable">
          </path>
          <path d="M292,270.88l.34,1a2.7,2.7,0,0,0,2.69,1.81l3.13-.14L297,266.1l-3.44,1.35A2.72,2.72,0,0,0,292,270.88Z"
            style="fill: rgb(206, 122, 99); transform-origin: 295.003px 269.897px;" id="el8j9clnbovl4" class="animable">
          </path>
          <path
            d="M336.12,228.79c0,2.32-1.12,6.9-2.08,10.34-.71,2.47-1.3,4.35-1.3,4.35l-1.41-.35-11.62-2.94c-.18-6,.05-12.53,6-16.45C329.43,221.28,336.09,224.66,336.12,228.79Z"
            style="fill: rgb(38, 50, 56); transform-origin: 327.896px 233.194px;" id="elakdisaftdtc" class="animable">
          </path>
          <g id="elldmx6qdlf8p">
            <path
              d="M336.11,229.19c-.76-.8-2.48-.94-6.23.25-5.37,1.7-6.87,7.58-6.94,11.57l8.39,2.12.72.19.69.16s.29-.94.71-2.37h0c.17-.58.38-1.26.58-2A54.34,54.34,0,0,0,336.11,229.19Z"
              style="opacity: 0.3; isolation: isolate; transform-origin: 329.525px 236.024px;" class="animable"></path>
          </g>
          <polygon points="312.27 415.47 305.18 412.99 309.64 397.63 316.72 400.12 312.27 415.47"
            style="fill: rgb(206, 122, 99); transform-origin: 310.95px 406.55px;" id="elixdwgv5l8s" class="animable">
          </polygon>
          <polygon points="407 397.27 401.23 401.57 389.42 389.6 395.19 385.3 407 397.27"
            style="fill: rgb(206, 122, 99); transform-origin: 398.21px 393.435px;" id="elgiyhl0tw85b" class="animable">
          </polygon>
          <path
            d="M398.87,399.82l6.23-5.15a.58.58,0,0,1,.76,0l4.78,4.34a1.15,1.15,0,0,1,0,1.63l-.1.09c-2.2,1.76-3.36,2.5-6.1,4.76-1.68,1.39-5.21,4.72-7.54,6.64s-4.35-.27-3.55-1.28c3.57-4.52,4.4-7.5,4.82-9.94A1.93,1.93,0,0,1,398.87,399.82Z"
            style="fill: rgb(38, 50, 56); transform-origin: 402.075px 403.71px;" id="ello6kf7c5bsn" class="animable">
          </path>
          <path
            d="M304.7,410.77l7.88,3.29a.71.71,0,0,1,.42.74l-.89,6.95a1.06,1.06,0,0,1-1.18.92,1,1,0,0,1-.3-.08c-2.79-1.13-4.1-1.69-7.56-3.14L292.45,415c-2.88-1.21-2.26-4.37-1-4.14,5.83,1.06,9.59.71,11.89-.16A1.77,1.77,0,0,1,304.7,410.77Z"
            style="fill: rgb(38, 50, 56); transform-origin: 301.7px 416.637px;" id="el62chimniyvk" class="animable">
          </path>
          <g id="elgey4k5f4egw">
            <polygon points="309.64 397.64 307.35 405.55 314.25 408.67 316.73 400.12 309.64 397.64"
              style="opacity: 0.2; isolation: isolate; transform-origin: 312.04px 403.155px;" class="animable">
            </polygon>
          </g>
          <g id="ely75sradf4ba">
            <polygon points="395.2 385.3 389.42 389.6 395.51 395.77 401.28 391.47 395.2 385.3"
              style="opacity: 0.2; isolation: isolate; transform-origin: 395.35px 390.535px;" class="animable">
            </polygon>
          </g>
          <path
            d="M357.92,266.33l-29.2.42c-4-22.61-5-33.4-5-38.59a5.56,5.56,0,0,1,4.5-5.48c1.78-.35,4-.75,6.23-1.06.76-.1,1.53-.2,2.29-.28a93.16,93.16,0,0,1,13.26-.19c.66,0,1.31.06,2,.1,3,.19,5.74.57,7.69.87a2.68,2.68,0,0,1,2.22,3.07.77.77,0,0,1,0,.15c-.86,4-1.56,7.55-2.12,10.71-.89,5-1.45,9.08-1.78,12.64A110.52,110.52,0,0,0,357.92,266.33Z"
            style="fill: rgb(38, 50, 56); transform-origin: 342.832px 243.875px;" id="elsg0kozfbhzj" class="animable">
          </path>
          <g id="el2ulf791wqwm">
            <path d="M358,248.72c-1.47-4.41-1.39-13.56.19-16.52l1.61,3.87C358.89,241.1,358.33,245.16,358,248.72Z"
              style="opacity: 0.1; isolation: isolate; transform-origin: 358.374px 240.46px;" class="animable"></path>
          </g>
          <path
            d="M328.72,266.75s-5,42.51-9.51,69c-3.57,20.74-12.59,63.91-12.59,63.91l11.71,4.15s13.41-45,15.76-64.22c9.45-27.71,16.25-73.05,16.25-73.05Z"
            style="fill: rgb(64, 123, 255); transform-origin: 328.48px 335.175px;" id="ellbwsxo0767m" class="animable">
          </path>
          <polygon points="304.72 402.56 318.27 407.33 320.08 402.98 305.38 397.36 304.72 402.56"
            style="fill: rgb(38, 50, 56); transform-origin: 312.4px 402.345px;" id="eli95fksur50i" class="animable">
          </polygon>
          <g id="elxf9914yih6g">
            <path d="M343.52,279.38l-4.38,8.39c-1.6,6.32-1.22,21.7.81,31.42,3.64-14.65,6.55-30.07,8.36-40.42Z"
              style="opacity: 0.1; isolation: isolate; transform-origin: 343.229px 298.98px;" class="animable"></path>
          </g>
          <path
            d="M335.06,266.75s5.23,47.58,15,74c4.9,13.25,41.25,52.53,41.25,52.53l8.2-6.56s-23.05-39.63-33.13-50c-4.11-23,1.06-57.48-8.49-70.38Z"
            style="fill: rgb(64, 123, 255); transform-origin: 367.285px 329.81px;" id="elw3y7sigfg3j" class="animable">
          </path>
          <polygon points="392 396.09 401.62 388.21 399.02 383.94 388.49 393.11 392 396.09"
            style="fill: rgb(38, 50, 56); transform-origin: 395.055px 390.015px;" id="elw4kol03r79f" class="animable">
          </polygon>
          <path
            d="M358.38,264.62l1.35,2.59c.1.2-.13.42-.47.42l-30.64.44c-.27,0-.48-.13-.51-.29l-.29-2.61c0-.18.21-.34.49-.34l29.58-.43A.58.58,0,0,1,358.38,264.62Z"
            style="fill: rgb(38, 50, 56); transform-origin: 343.787px 266.234px;" id="el23nxt9xyz32" class="animable">
          </path>
          <path
            d="M354.41,268h.81c.15,0,.27-.09.26-.18l-.43-3.4c0-.1-.14-.17-.3-.17H354c-.16,0-.28.09-.26.18l.42,3.39C354.11,267.9,354.26,268,354.41,268Z"
            style="fill: rgb(64, 123, 255); transform-origin: 354.609px 266.125px;" id="elgr49pvigunk" class="animable">
          </path>
          <path
            d="M330.82,268.32h.8c.16,0,.27-.09.26-.19l-.41-3.39c0-.1-.16-.18-.32-.18h-.8c-.16,0-.28.09-.27.19l.42,3.39C330.52,268.24,330.66,268.32,330.82,268.32Z"
            style="fill: rgb(64, 123, 255); transform-origin: 330.98px 266.44px;" id="el97exvseb43" class="animable">
          </path>
          <path
            d="M342.62,268.13h.8c.16,0,.28-.09.27-.19l-.42-3.39c0-.11-.15-.19-.31-.18h-.8c-.16,0-.27.09-.27.19l.42,3.39C342.3,268.07,342.46,268.13,342.62,268.13Z"
            style="fill: rgb(64, 123, 255); transform-origin: 342.79px 266.25px;" id="ele2fixgecgm5" class="animable">
          </path>
          <polygon points="289.64 236.82 332.98 230.87 338.98 273.42 296.42 279.26 289.64 236.82"
            style="fill: rgb(64, 123, 255); transform-origin: 314.31px 255.065px;" id="elfp4wqfqo61l" class="animable">
          </polygon>
          <g id="el3az3j490grg">
            <polygon points="289.64 236.82 332.98 230.87 338.98 273.42 296.42 279.26 289.64 236.82"
              style="fill: rgb(255, 255, 255); opacity: 0.8; isolation: isolate; transform-origin: 314.31px 255.065px;"
              class="animable"></polygon>
          </g>
          <g id="eloaj53dqu0li">
            <rect x="324.28" y="230.89" width="11.69" height="44.34"
              style="fill: rgb(64, 123, 255); opacity: 0.2; isolation: isolate; transform-origin: 330.125px 253.06px; transform: rotate(-8.04906deg);"
              class="animable"></rect>
          </g>
          <path d="M345.27,268.06l-6-2.35.59,7.5s4-.2,6.15-2.29Z"
            style="fill: rgb(206, 122, 99); transform-origin: 342.64px 269.46px;" id="elrj4dn84ehf" class="animable">
          </path>
          <path
            d="M364.14,230.72c1.61,4.5,3,9,4.18,13.6.6,2.31,1.16,4.63,1.61,7,.22,1.2.41,2.42.55,3.69a25.66,25.66,0,0,1,.17,4,10.67,10.67,0,0,1-.4,2.57,7.42,7.42,0,0,1-1.61,2.77,12.77,12.77,0,0,1-3.75,2.77,33.27,33.27,0,0,1-7.24,2.53,76.06,76.06,0,0,1-14.25,1.91l-.47-3.77c2.19-.52,4.43-1.1,6.61-1.71s4.35-1.29,6.43-2.05a32.44,32.44,0,0,0,5.72-2.61,6.64,6.64,0,0,0,1.74-1.47c.13-.2.13-.25.11-.25a6.61,6.61,0,0,0,0-.89,21.46,21.46,0,0,0-.31-2.89c-.16-1-.38-2.12-.62-3.22-.5-2.14-1.06-4.33-1.69-6.52-1.25-4.34-2.66-8.75-4.07-13Z"
            style="fill: rgb(206, 122, 99); transform-origin: 356.798px 251.14px;" id="elfv2udxry6ik" class="animable">
          </path>
          <path
            d="M333.74,269.41l.47,1.74a2.65,2.65,0,0,0,2.49,2l3.22.09-.59-7.49-3.28.37a2.66,2.66,0,0,0-2.38,2.92A2.7,2.7,0,0,0,333.74,269.41Z"
            style="fill: rgb(206, 122, 99); transform-origin: 336.788px 269.495px;" id="elmf06nngmnwe" class="animable">
          </path>
          <path
            d="M360.73,222.34c3.36,1.37,6.11,12.41,6.11,12.41L357.56,242s-6.58-10.3-5.24-14.77C353.46,223.45,356.45,220.61,360.73,222.34Z"
            style="fill: rgb(38, 50, 56); transform-origin: 359.49px 231.91px;" id="elbtjqdrles7a" class="animable">
          </path>
          <path
            d="M396.6,402.94h-.12a1,1,0,0,1-.81-.52.59.59,0,0,1-.05-.59c.41-.8,3-1.12,3.28-1.15a.16.16,0,0,1,.17.08.17.17,0,0,1,0,.19C398.53,401.68,397.48,402.88,396.6,402.94Zm1.95-1.87c-1,.15-2.41.48-2.61.92a.23.23,0,0,0,0,.25.62.62,0,0,0,.53.36c.5,0,1.3-.54,2.1-1.53Z"
            style="fill: rgb(38, 50, 56); transform-origin: 397.331px 401.809px;" id="eldigisz0ympm" class="animable">
          </path>
          <path
            d="M398.78,401c-.87,0-2.61-.31-3-1a.6.6,0,0,1,.14-.73.89.89,0,0,1,.7-.29c1.05,0,2.38,1.61,2.42,1.7a.15.15,0,0,1,0,.16.15.15,0,0,1-.13.11Zm-2.22-1.66a.59.59,0,0,0-.37.18c-.15.16-.12.26-.08.34.23.44,1.6.8,2.48.8a4,4,0,0,0-1.95-1.33Z"
            style="fill: rgb(38, 50, 56); transform-origin: 397.388px 399.989px;" id="elbqlxf4zt0s7" class="animable">
          </path>
          <path
            d="M300.67,410.61a2.82,2.82,0,0,1-1.39-1,1.1,1.1,0,0,1-.08-.92.52.52,0,0,1,.38-.35c.92-.17,3.09,2.16,3.51,2.63a.18.18,0,0,1,0,.2.16.16,0,0,1-.18.08A11.51,11.51,0,0,1,300.67,410.61Zm-.69-1.88a.63.63,0,0,0-.33-.06s-.09,0-.13.12a.72.72,0,0,0,.06.64c.31.54,1.38,1.05,2.9,1.38A10.42,10.42,0,0,0,300,408.73Z"
            style="fill: rgb(38, 50, 56); transform-origin: 301.127px 409.793px;" id="elq6hjrn9k5ri" class="animable">
          </path>
          <path
            d="M302.9,411.23l-.06,0c-.73-.73-1.92-2.92-1.51-3.62.09-.15.28-.31.71-.19,1.61.47,1.12,3.59,1.09,3.72a.13.13,0,0,1-.12.13A.11.11,0,0,1,302.9,411.23Zm-.8-3.49H302c-.16-.05-.28,0-.32,0-.24.41.53,2.06,1.23,2.94C302.9,409.91,303,408.14,302.1,407.74Z"
            style="fill: rgb(38, 50, 56); transform-origin: 302.235px 409.325px;" id="el8l3d6cyelpb" class="animable">
          </path>
          <path d="M333.08,191.23s-4,6.69-1,9.62,3.88-7.46,3.88-7.46Z"
            style="fill: rgb(38, 50, 56); transform-origin: 333.462px 196.3px;" id="elbrzzxt2f47l" class="animable">
          </path>
          <path
            d="M351.66,206.93s5-7.82,2.92-15.59-11.8-2.75-15.9-4.88a23.46,23.46,0,0,0-4.77-2.21,1.83,1.83,0,0,0-1.59.27c-1,.88.74,2.53,1.28,3.15a5.52,5.52,0,0,0-1.14-1c-.53-.35-1.24-.38-1,.51a4.4,4.4,0,0,0,2.08,2.2c-.39-.22-2.7-1.39-2.86-.66a.72.72,0,0,0,.13.5c1.5,2.8,4.52,6.32,8,6.39C343,195.67,347,207.46,351.66,206.93Z"
            style="fill: rgb(38, 50, 56); transform-origin: 342.881px 195.561px;" id="el6nicy6uped5" class="animable">
          </path>
          <path
            d="M353.43,207.28c-.26,2.64-4.18,2.67-4.18,2.67a8.48,8.48,0,0,1-3.07,3.72,22,22,0,0,0,.16,4.78c.66,2.49,1.5,3.35,2.55,3.91h0l-.84.83a11,11,0,0,1-5.28,1.47l-1.1-.64-1.44.23c-4-1.31-3-4-3-4a8.09,8.09,0,0,0,.11-5.71c-3.84-1.2-5.41-4.54-5.95-10.16s2.62-11,2.62-11c4.34,4.19,12.94,1.5,12.94,1.5-.37,7.88,3.31,10.79,3.31,10.79C351.47,204.59,353.68,204.65,353.43,207.28Z"
            style="fill: rgb(206, 122, 99); transform-origin: 342.389px 209.02px;" id="elx8u2ymi7gu" class="animable">
          </path>
          <path
            d="M339.47,208a4.42,4.42,0,0,0,2.51-1.84.33.33,0,0,0-.08-.45.32.32,0,0,0-.45.07,3.25,3.25,0,0,1-3.51,1.6.32.32,0,0,0-.4.21.33.33,0,0,0,.21.41A3.4,3.4,0,0,0,339.47,208Z"
            style="fill: rgb(38, 50, 56); transform-origin: 339.78px 206.879px;" id="el8ii3tnnw5nj" class="animable">
          </path>
          <path d="M340.61,201.12c0,.51-.2.95-.54,1s-.65-.37-.69-.88.21-1,.55-1S340.57,200.6,340.61,201.12Z"
            style="fill: rgb(38, 50, 56); transform-origin: 339.993px 201.182px;" id="el2jaklk32iwk" class="animable">
          </path>
          <path d="M334.49,200.86c0,.51-.2.95-.54,1s-.64-.37-.68-.88.2-.95.54-1S334.46,200.34,334.49,200.86Z"
            style="fill: rgb(38, 50, 56); transform-origin: 333.878px 200.92px;" id="elcrdd608eyd" class="animable">
          </path>
          <path d="M336.11,205.81l-.07-3.7-1.84,3.12A2.3,2.3,0,0,0,336.11,205.81Z"
            style="fill: rgb(186, 77, 60); transform-origin: 335.155px 203.974px;" id="elkl7tmnnsyip" class="animable">
          </path>
          <path
            d="M343.4,198a.34.34,0,0,0,.12-.06.42.42,0,0,0,.14-.59,3.47,3.47,0,0,0-3.27-1.68.42.42,0,0,0-.37.48.43.43,0,0,0,.48.37h0a2.64,2.64,0,0,1,2.43,1.28A.43.43,0,0,0,343.4,198Z"
            style="fill: rgb(38, 50, 56); transform-origin: 341.871px 196.835px;" id="eld5dr2m279ok" class="animable">
          </path>
          <path
            d="M332.55,197.59a.45.45,0,0,0,.27-.2,1.68,1.68,0,0,1,1.68-.73.42.42,0,0,0,.5-.34.43.43,0,0,0-.34-.5,2.5,2.5,0,0,0-2.58,1.14.44.44,0,0,0,.15.59A.45.45,0,0,0,332.55,197.59Z"
            style="fill: rgb(38, 50, 56); transform-origin: 333.515px 196.691px;" id="eldg3cbpctxev" class="animable">
          </path>
          <polygon
            points="350.52 222.71 348.37 224.81 344.54 228.57 343.1 226.28 341.67 224.02 346.67 218.06 350.31 222.46 350.52 222.71"
            style="fill: rgb(255, 255, 255); transform-origin: 346.095px 223.315px;" id="el3lkxfmsbq1a"
            class="animable"></polygon>
          <polygon points="341.67 224.02 340.56 225.87 338.93 228.57 335.19 221.82 337.58 219.01 341.67 224.02"
            style="fill: rgb(255, 255, 255); transform-origin: 338.43px 223.79px;" id="el6rn0try82ff" class="animable">
          </polygon>
        </g>
        <defs>
          <filter id="active" height="200%">
            <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
            <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
            <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
            <feMerge>
              <feMergeNode in="OUTLINE"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <filter id="hover" height="200%">
            <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
            <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
            <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
            <feMerge>
              <feMergeNode in="OUTLINE"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
            <feColorMatrix type="matrix"
              values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 ">
            </feColorMatrix>
          </filter>
        </defs>
        <defs>
          <clipPath id="freepik--clip-path--inject-158">
            <path
              d="M297.68,348.92h-17c-8.85,0-16,12.83-16,28.66s7.17,28.65,16,28.65h17c8.85,0,16-12.83,16-28.65S306.53,348.92,297.68,348.92Z"
              style="fill:#263238"></path>
          </clipPath>
        </defs>
      </svg>
