import { Inject, Injectable, Component } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const SAVED_THEME_SETTING_KEY = 'local_theme_setting';

//LOCAL STORAGE FUNCTIONS
export interface SavedThemeObj {
  name: string,
  isDark: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  static StorageManager: LocalStorageService;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {

  }

  public saveCurrentThemeOnClient(saveThemeObject:SavedThemeObj): void {

    this.storage.set(SAVED_THEME_SETTING_KEY, saveThemeObject);

  }

  public loadThemeFromClient(): SavedThemeObj {

    return this.storage.get(SAVED_THEME_SETTING_KEY) || {};

  }

}


