<mat-sidenav-container pin>
    <!-- <mat-sidenav #drawer sidenav fixedInViewport="true">
        <mat-toolbar>Menue</mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="../Home">.</a>
            <a id="hiddenDL" style="display: none;"></a>

        </mat-nav-list>
    </mat-sidenav> -->


    <mat-sidenav-content>
        <mat-toolbar color="primary" class="mat-elevation-z4" stdborder style="z-index: 999;">
            <mat-toolbar-row flex>

                <!-- <div *ngIf="width < 1023" MR70>
                    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                    </button>
                </div> -->

                <div tlbBTNDiv>
                    <button mat-icon-button tlbBTN routerLink="../Home"></button>
                </div>

                <a style="float: right;">
                    {{authedUser}}
                </a>

                <button adminMenu mat-icon-button id="theme-selector" [mat-menu-trigger-for]="themeMenu" matTooltip="Select a theme!" tabindex="-1" style="float: right;">
                    <mat-icon class="auto-flip">settings_suggest</mat-icon>
                </button>

                <mat-menu #themeMenu="matMenu">

                    <mat-slide-toggle [checked]="themeManager.isThemeDark" (click)="$event.stopPropagation()" (change)="themeManager.SetDarkMode = $event.checked;" class="mat-menu-item theme-selector-is-dark">Dark Mode
                    </mat-slide-toggle>
                    <!-- <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
                    <mat-grid-list cols="2" class="theme-selectors-container">
                        <mat-grid-tile *ngFor="let theme of themeManager.themes">
                            <button mat-button class="theme-selector" (click)="$event.stopPropagation(); themeManager.SetTheme = theme;">
                                <div [attr.class]="theme + ' theme-primary center'">
                                    <div [attr.class]="theme + ' theme-accent'"></div>
                                    <mat-icon *ngIf="themeManager.activeTheme === theme" class="center theme-check">
                                        check</mat-icon>
                                </div>
                            </button>
                        </mat-grid-tile>
                    </mat-grid-list> -->
                    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
                    <button *ngIf="!showAdmin" mat-menu-item (click)="showAdmin=true">
                        <mat-icon>admin_panel_settings</mat-icon>Admin Portal
                    </button>
                    <button *ngIf="showAdmin" mat-menu-item (click)="showAdmin=false">
                        <mat-icon>reply</mat-icon>Back To Site

                    </button>
                    <button *ngIf="showAdmin" mat-menu-item (click)="Logout(); showAdmin=false; isAuthed=false;">
                        <mat-icon>logout</mat-icon>Log Out
                    </button>
                </mat-menu>

            </mat-toolbar-row>
        </mat-toolbar>

        <router-outlet *ngIf="!showAdmin"></router-outlet>


        <div *ngIf="showAdmin">


            <mat-card mat-login-card *ngIf="!isAuthed" style="z-index: 900; width: 350px;  justify-content: center;" mat-elevation-z24>


                <mat-card-title style="font-weight: 200; font-size: 2.5em;">Admin</mat-card-title>
                &nbsp;
                <mat-card-subtitle *ngIf="!isAuthed" style="font-weight: 100; font-size: 1.5em;">sign in
                </mat-card-subtitle>

                <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>

                <br>

                <mat-card-content>

                    <form *ngIf="!isAuthed" class="login-form">

                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput #mail type="mail" [formControl]="username">
                            <button mat-icon-button matSuffix (click)="mail.value = ''">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <br>

                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput #pass [type]="hidePass ? 'password' : 'text'" [formControl]="password" (cdkAutofill)="autofilled($event);" (input)="passChanged();">

                            <button *ngIf="!canShowPass" mat-icon-button matSuffix (click)="pass.value = '';canShowPass = true;">
                                <mat-icon>clear</mat-icon>
                            </button>

                            <button *ngIf="canShowPass" mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
                                <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>

                        </mat-form-field>

                    </form>

                    <button *ngIf="!isAuthed" mat-flat-button color="primary" class="sample-elements" style="width:90%; font-size: 2em; height: 50px;" (click)="LoginAuth();">Sign In
                    </button>

                    <button *ngIf="isAuthed" mat-flat-button color="primary" class="sample-elements" style="width:90%; font-size: 2em; height: 50px;" (click)="Logout();">Sign Out
                    </button>

                    <div style="width: 100%; height: 10px; font-size: 0.7em; font-weight: 100; text-align: center;">
                        v2024.7 (C)</div>

                </mat-card-content>
            </mat-card>










            <div *ngIf="isAuthed">
                <!-- <mat-card *ngIf="!selectedApplicant" style="z-index: 900; margin: 25px;  justify-content: center;" mat-elevation-z24>

                    <mat-card-title style="font-weight: 200; font-size: 2.5em;">Online Applications</mat-card-title>
                    &nbsp;
                    <mat-card-subtitle>Total Applicants On System: {{applicantCount}}
                    </mat-card-subtitle>
                    <mat-card-content>
                    </mat-card-content>
                </mat-card> -->

                <div>

                    <mat-card *ngIf="!selectedApplicant" tableCard class="mat-elevation-z8">

                        <mat-card-title style="font-weight: 200; font-size: 2.5em;">Online Applications</mat-card-title>

                        &nbsp;

                        <div *ngIf="!tableData" style="display: flex; flex-direction: column; align-items: center; margin-top: 50px;">
                            <mat-spinner diameter="50" ></mat-spinner>
                            <h1>Loading...</h1> <br>
                        </div>

                        <div *ngIf="tableData">
<!-- 
    
                        <mat-card-subtitle>Total Applicants On System: {{applicantCount}}</mat-card-subtitle>
                        <mat-card-subtitle>Total Applicants Of Last 3 Months: {{tableData.data.length}}</mat-card-subtitle> -->

                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Search Applicants</mat-label>

                            <input matInput #filter autocomplete="off" (input)="applyFilter(filter.value);">

                        </mat-form-field>

                        <table mat-table [dataSource]="tableData!" matSort class="user-table">

                            <ng-container matColumnDef="in_date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Application Date</th>
                                <td mat-cell *matCellDef="let applicant">{{datePipe.transform(applicant.in_date,'YYYY-MM-dd hh:mm:ss a')}}</td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let applicant">{{getStatusText(applicant.status)}}</td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                <td mat-cell *matCellDef="let applicant">{{applicant.name}}</td>
                            </ng-container>

                            <ng-container matColumnDef="surname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
                                <td mat-cell *matCellDef="let applicant">{{applicant.surname}}</td>
                            </ng-container>

                            <ng-container matColumnDef="id_number">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID/Passport/Assylum</th>
                                <td mat-cell *matCellDef="let applicant">{{applicant.id_passport_number}}

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                                <td mat-cell *matCellDef="let applicant">
                                    <a class="blur-data" href="tel:{{applicant.phone}}" (click)="$event.stopPropagation()">
                                        {{applicant.phone}}
                                    </a>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="mail">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                                <td mat-cell *matCellDef="let applicant">
                                    <a class="blur-data" href="mailto:{{applicant.email}}" (click)="$event.stopPropagation()">
                                        {{applicant.email}}
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="province">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Province</th>
                                <td mat-cell *matCellDef="let applicant">{{applicant.province}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="documents">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Documents</th>
                                <td mat-cell *matCellDef="let applicant">{{applicant.documents.length}}
                                </td>
                            </ng-container>

                            <tr class="table-row" mat-header-row *matHeaderRowDef="displayedColumns" [class]="themeManager.activeTheme + ' theme-fg-accent'"></tr>

                            <tr class="table-row"
                                mat-row
                                (click)="tableItemClicked(row)"
                                [class.demo-row-is-clicked]="clickedRows.has(row)"
                                *matRowDef="let row; columns: displayedColumns;">
                            </tr>

                        </table>
                        
                    </div>
                    <mat-paginator [pageSizeOptions]="[15, 30, 60, 120]" showFirstLastButtons ></mat-paginator>


                    </mat-card>

                    <div *ngIf="selectedApplicant" style="display: flex; flex-direction: row;">



                        <mat-card applpicantCard class="mat-elevation-z8">



                            <div style="display: flex; flex-direction: row; justify-content: space-evenly; ">
                                <button mat-mini-fab color="primary" aria-label="Example icon button with a delete icon" (click)="selectedApplicant = null; fetchApplicants();">
                                    <mat-icon>arrow_back</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary" aria-label="Example icon button with a delete icon" (click)="printDiv('prindiv'+selectedApplicant.id);">
                                    <mat-icon>print</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary" aria-label="Example icon button with a delete icon" (click)="downloadApplicant(selectedApplicant.id);">
                                    <mat-icon>download</mat-icon>
                                </button>
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: space-evenly; ">
                                <a style="color: rgb(0, 143, 187); cursor:pointer" href="" download="" id="zipdownload"></a>&nbsp;
                            </div>
                            <hr>
                            <div style="display:flex;flex-direction:column;justify-content: space-between;">
                                <div id="{{'prindiv'+selectedApplicant.id}}">


                                    <div style="display: flex; flex-direction: column; width: 100%; position: relative;">
                                        <h2><u>Applicant Information</u></h2>
                                        <div rowDiv>
                                            <a rowTitle>Name:</a>
                                            <a rowData>{{selectedApplicant.name}}
                                                {{selectedApplicant.surname}}</a>
                                        </div>


                                        <!-- <div rowDiv>
                                    <a rowTitle>Occupation:</a>
                                    <a rowData>{{selectedApplicant.occupation}}</a>
                                </div> -->
                                        <div rowDiv>
                                            <a rowTitle>{{selectedApplicant.id_type}}:</a>
                                            <a rowData>{{selectedApplicant.id_passport_number}}</a>

                                        </div>

                                        <div rowDiv>
                                            <a rowTitle>DOB:</a>
                                            <a rowData>{{selectedApplicant.date_of_birth}}</a>
                                        </div>

                                        <div rowDiv>
                                            <a rowTitle>Contact:</a>
                                            <a rowData style="color: rgb(0, 143, 187);" href="tel:{{selectedApplicant.phone}}">{{selectedApplicant.phone}}</a>
                                        </div>
                                        <div rowDiv>
                                            <a rowTitle>Email:</a>
                                            <a rowData style="color: rgb(0, 143, 187);" href="mailto:{{selectedApplicant.email}}">{{selectedApplicant.email}}</a>
                                        </div>

                                        <div rowDiv>
                                            <a rowTitle>Province:</a>
                                            <a rowData> {{selectedApplicant.province}}</a>
                                        </div>

                                        <div rowDiv>
                                            <a rowTitle>Address:</a>
                                            <a rowData> {{selectedApplicant.street}}
                                                {{selectedApplicant.city}}
                                                {{selectedApplicant.postal_code}}</a>
                                        </div>
                                        <!-- <div rowDiv>
                                    <a rowTitle>License:</a>
                                    <a rowData>Code:
                                        {{selectedApplicant.vehicle_license_code}} Expires:
                                        {{selectedApplicant.vehicle_license_expiring_date}}</a>
                                </div> -->
                                        <div rowDiv>
                                            <a rowTitle>Bike License:</a>
                                            <a rowData>Has License: {{selectedApplicant.has_bike_lic}}
                                                Expires:
                                                {{selectedApplicant.bike_license_expiring_date}}</a>
                                        </div>
                                        <!-- <div rowDiv>
                                    <a rowTitle>PDP Expires:</a>
                                    <a rowData> {{selectedApplicant.pdp_expire_date}}</a>
                                </div> -->
                                        <div rowDiv>
                                            <a rowTitle>Previous Experience:</a>
                                            <a rowData>{{selectedApplicant.applicant_experience}}</a>
                                        </div>
                                        <div rowDiv>
                                            <a rowTitle>Applied On:</a>
                                            <a rowData>{{datePipe.transform(selectedApplicant.in_date,
                                                'YYYY-MM-dd hh:mm:ss')}}</a>
                                        </div>
                                        <div rowDiv>
                                            <a rowTitle>Sizes:</a>
                                            <a rowData>Shirt: {{selectedApplicant.shirt_size}},
                                                Pants:
                                                {{selectedApplicant.pants_size}},
                                                Shoes:
                                                {{selectedApplicant.shoe_size}}</a>                                      
                                        </div>

                                    </div>

                                    <div style="border: 1px dashed rgb(155, 155, 155);" style="display: flex; flex-direction: column; width: 100%; position: relative; margin-top: 40px;">
                                        <h2><u>Applicant Documents</u></h2>

                                        <a style=" color: rgb(0, 132, 255);">Documents:
                                            {{selectedApplicant.documents.length}} </a>
                                        <div *ngFor="let document of selectedApplicant.documents;let docidex = index" style="border-bottom: 1px dashed;">
                                            <a style="color: rgb(0, 143, 187); cursor:pointer" (click)="setImgSrc('https://drive4pingo.co.za/media/uploads/' + document.path)">View</a>&nbsp;
                                            <a style="color: rgb(0, 143, 187); cursor:pointer" href="https://drive4pingo.co.za/media/uploads/{{document.path}}" download="https://drive4self.co.za/uploads/{{document.path}}">download</a>&nbsp;
                                            <a>{{document.document_type}} Document</a>

                                        </div>
                                        <mat-progress-bar *ngIf="isLoadingDoc" mode="indeterminate"></mat-progress-bar>
                                    </div>
                                </div>
                                <div style="border: 1px dashed rgb(155, 155, 155);" style="display: flex; flex-direction: column; width: 100%; position: relative; margin-top: 40px;">
                                    <h2><u>Applicant Status</u></h2>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Status:</mat-label>
                                        <mat-select matInput #IDType required (selectionChange)="1" [(ngModel)]="selectedApplicant.status">
                                            <mat-option *ngFor="let status of applicantStatuses"
                                                        [value]="status.id">{{status.status}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Notes:</mat-label>
                                        <div>
                                            <input matInput required (keyup)="1" [(ngModel)]="selectedApplicant.applicant_note">
                                        </div>
                                    </mat-form-field>

                                    <div style="display: flex; flex-direction: row; justify-content: space-evenly; ">
                                        <button mat-raised-button color="warn" style="margin-top: 20px; float: right; width: 45%;"
                                                (click)="selectedApplicant = null; fetchApplicants();">
                                            Back
                                        </button>
                                        <button mat-raised-button style="margin-top: 20px; float: right; width: 45%; background-color: rgb(93, 141, 22);"
                                                (click)="updateApplicant();">
                                            Save
                                        </button>
                                    </div>

                                </div>


                            </div>



                        </mat-card>


                        <mat-card documentViewerCard class="mat-elevation-z8">

                            <!-- <iframe style="width: 100%; height: 100%;" id='photoViewer' src=""> </iframe> -->
                            <img style="width: 100%;" id='photoViewer' src="">
                        </mat-card>


                    </div>

                </div>
            </div>




        </div>


    </mat-sidenav-content>
</mat-sidenav-container>