import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { error } from '@angular/compiler/src/util';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export enum CallType {
  GET = 0,
  POST = 1,
  DELETE = 2,
  PUT = 3
}



@Injectable({
  providedIn: 'root'
})
export class APICaller {

  // set host url here
  //hostUrl = 'http://localhost:8000';
  hostUrl = window.location.protocol + '//' + window.location.hostname;


  constructor(private http: HttpClient) { }




  private PrepHeaderPayload(): HttpHeaders {
    let headers: HttpHeaders;

    // if (sessionVars.loginToken) {
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //     'Authorization': 'Token ' + sessionVars.loginToken
    });
    return headers;
    // };
    return headers;
  }

  private PrepFormHeaderPayload(): HttpHeaders {
    let headers: HttpHeaders;

   // if (sessionVars.loginToken) {
      headers = new HttpHeaders({
        // 'Content-Type': 'false',
        "mimeType": "multipart/form-data",
        "processData":'false'
      })
      return headers;
   // };
    return headers;
  }

  // generic post function to send pre-made forms to a endpoint
  PostFromData<T>(endpoint: string, form: FormData, callResponse?: (r: T) => void, callError?: (e: T) => void): void {
    const headers = this.PrepFormHeaderPayload();

    this.http.post<T>(`${this.hostUrl}${endpoint}`, form, { headers, withCredentials: true }).subscribe(formResponse => {
      callResponse?.(formResponse);
    }, formError => {
      callError?.(formError);
    });

  }

  uploadFile(endpoint: string, form: FormData,): Observable<any> {
    

    return this.http.post(`${this.hostUrl}${endpoint}`, form, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


  CallEndPoint<T>(endpoint: string, body: any = null, calltype: CallType, callResponse?: (r: T) => void, callError?: (e: T) => void): void {

    const headers = this.PrepHeaderPayload();

    if (calltype === CallType.GET) {

      this.http.get<T>(`${this.hostUrl}${endpoint}`, { headers, withCredentials: true }).subscribe(getResponse => {
        callResponse?.(getResponse);
      }, getError => {
        callError?.(getError);
      });

    } else if (calltype === CallType.POST) {

      this.http.post<T>(`${this.hostUrl}${endpoint}`, body, { headers, withCredentials: true }).subscribe(postResponse => {
        callResponse?.(postResponse);
      }, postError => {
        callError?.(postError);
      });

    } else if (calltype === CallType.DELETE) {

      this.http.delete<T>(`${this.hostUrl}${endpoint}`, { headers, withCredentials: true }).subscribe(deleteResponse => {
        callResponse?.(deleteResponse);
      }, deleteError => {
        callError?.(deleteError);
      });

    } else if (calltype === CallType.PUT) {

      this.http.put<T>(`${this.hostUrl}${endpoint}`, body, { headers, withCredentials: true }).subscribe(putResponse => {
        callResponse?.(putResponse);
      }, putError => {
        callError?.(putError);
      });

    }
  }

}
