import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms';
import { TileStyler } from '@angular/material/grid-list/tile-styler';
import { Router } from '@angular/router';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  showFaq: boolean = false;

  showDisclaimer: boolean = false;

  constructor(private route: Router) {


  }

  ngOnInit(): void {

  }


  toggleFAQ() {
    this.showFaq = !this.showFaq;

    if (this.showFaq) {
      const element = document.getElementById("docStart");
      element.scrollIntoView();
    }else{
      const element = document.getElementById("applyStart");
      element.scrollIntoView();

    }
  }

  toggleDisclaimer() {
    this.showDisclaimer = !this.showDisclaimer;

  }



  printDiv() {

    var printContents = document.getElementById('coversheet')?.innerHTML;
    var originalContents = document.body.innerHTML;

    var wnd = window.open("#", "", "_blank");
    wnd?.resizeTo(800, 960);
    wnd?.document.write(printContents!);
    wnd?.print();
  }

}


