<svg class="animated" id="freepik_stories-take-away" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
  version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
  <g id="freepik--background-complete--inject-64" class="animable" style="transform-origin: 250px 226.81px;">
    <rect y="382.4" width="500" height="0.25" style="fill: rgb(235, 235, 235); transform-origin: 250px 382.525px;"
      id="el6je5jatrtiw" class="animable"></rect>
    <rect x="104.54" y="389.21" width="33.12" height="0.25"
      style="fill: rgb(235, 235, 235); transform-origin: 121.1px 389.335px;" id="elhw2o6jr35su" class="animable"></rect>
    <rect x="171.14" y="398.37" width="40.75" height="0.25"
      style="fill: rgb(235, 235, 235); transform-origin: 191.515px 398.495px;" id="eloweb2c1157d" class="animable">
    </rect>
    <rect x="52.46" y="395.24" width="51.08" height="0.25"
      style="fill: rgb(235, 235, 235); transform-origin: 78px 395.365px;" id="elmxmx53y26pa" class="animable"></rect>
    <rect x="356.76" y="390.89" width="93.14" height="0.25"
      style="fill: rgb(235, 235, 235); transform-origin: 403.33px 391.015px;" id="elntbpq1cygne" class="animable">
    </rect>
    <rect x="341.53" y="390.89" width="6.33" height="0.25"
      style="fill: rgb(235, 235, 235); transform-origin: 344.695px 391.015px;" id="el237vr47fku6" class="animable">
    </rect>
    <rect x="277.21" y="395.11" width="42.23" height="0.25"
      style="fill: rgb(235, 235, 235); transform-origin: 298.325px 395.235px;" id="el125boq8obgi" class="animable">
    </rect>
    <path
      d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71h0V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.48,5.48,0,0,0,5.46-5.46V60.66A5.48,5.48,0,0,0,237,55.2Z"
      style="fill: rgb(235, 235, 235); transform-origin: 140.46px 196.4px;" id="ell6tskkzrq5i" class="animable"></path>
    <path
      d="M453.31,337.8H260.21a5.73,5.73,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.7,5.7,0,0,1,453.31,337.8ZM260.21,55.2a5.48,5.48,0,0,0-5.46,5.46V332.09a5.48,5.48,0,0,0,5.46,5.46h193.1a5.48,5.48,0,0,0,5.46-5.46V60.66a5.48,5.48,0,0,0-5.46-5.46Z"
      style="fill: rgb(235, 235, 235); transform-origin: 356.75px 196.4px;" id="el4smj9sy06m3" class="animable"></path>
    <path
      d="M70.67,171.1h86.76s-4.67-10.86-20.27-7.48c0,0-3.28-12.35-15.18-10.64,0,0-9.85-10.68-24.22-6.54s-13.13,17.18-13.13,17.18S74,160.84,70.67,171.1Z"
      style="fill: rgb(250, 250, 250); transform-origin: 114.05px 158.293px;" id="el0wyrq5besvee" class="animable">
    </path>
    <path
      d="M302.89,104.94H216.14s4.66-10.87,20.26-7.49c0,0,3.28-12.35,15.19-10.64,0,0,9.85-10.67,24.21-6.53s13.14,17.17,13.14,17.17S299.61,94.68,302.89,104.94Z"
      style="fill: rgb(250, 250, 250); transform-origin: 259.515px 92.1328px;" id="elj4bvvnpnrg" class="animable">
    </path>
    <path
      d="M366.87,102.11h66.37S430.7,93,421.1,96c0,0,2-12.26-5.08-13.68s-9.33,7.91-9.33,7.91a13.15,13.15,0,0,0-13.27-4,13.36,13.36,0,0,0-9.6,9.71S372,89.17,366.87,102.11Z"
      style="fill: rgb(250, 250, 250); transform-origin: 400.055px 92.1415px;" id="elbhsct3jcbdb" class="animable">
    </path>
    <rect x="45.96" y="222.17" width="80.74" height="159.97"
      style="fill: rgb(230, 230, 230); transform-origin: 86.33px 302.155px;" id="el493g52twp8y" class="animable"></rect>
    <rect x="45.96" y="222.17" width="66.06" height="159.97"
      style="fill: rgb(240, 240, 240); transform-origin: 78.99px 302.155px;" id="eldlafg1cd3na" class="animable"></rect>
    <rect x="44.22" y="222.17" width="83.43" height="2.61"
      style="fill: rgb(224, 224, 224); transform-origin: 85.935px 223.475px;" id="el1vhfxn4ktrq" class="animable">
    </rect>
    <rect x="44.22" y="216.95" width="83.43" height="2.61"
      style="fill: rgb(224, 224, 224); transform-origin: 85.935px 218.255px;" id="el36vg7kkz5o" class="animable"></rect>
    <rect x="45.96" y="219.56" width="79.94" height="2.61"
      style="fill: rgb(240, 240, 240); transform-origin: 85.93px 220.865px;" id="ely88hz52qivq" class="animable"></rect>
    <rect x="52.23" y="231.58" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 58.435px 237.88px;" id="el87sly2fee0k" class="animable"></rect>
    <rect x="53.32" y="231.58" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 58.975px 237.88px;" id="el9rrkhuks1bj" class="animable"></rect>
    <rect x="71.02" y="231.58" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 77.225px 237.88px;" id="el16tmw0e0c8p" class="animable"></rect>
    <rect x="72.11" y="231.58" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 77.765px 237.88px;" id="eldf0x2e53xwl" class="animable"></rect>
    <rect x="89.81" y="231.58" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 96.015px 237.88px;" id="elnra6e2otgs" class="animable"></rect>
    <rect x="90.9" y="231.58" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 96.555px 237.88px;" id="el6dnpo3qllze" class="animable"></rect>
    <rect x="52.23" y="250.13" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 58.435px 256.43px;" id="el1nx9wk2lpo6" class="animable"></rect>
    <rect x="53.32" y="250.13" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 58.975px 256.43px;" id="elwlyurusct5o" class="animable"></rect>
    <rect x="71.02" y="250.13" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 77.225px 256.43px;" id="el7u47cl429gi" class="animable"></rect>
    <rect x="72.11" y="250.13" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 77.765px 256.43px;" id="el99h7kg5r2jk" class="animable"></rect>
    <rect x="89.81" y="250.13" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 96.015px 256.43px;" id="elz8nh4uwmc6" class="animable"></rect>
    <rect x="90.9" y="250.13" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 96.555px 256.43px;" id="elhv7lr4i3zes" class="animable"></rect>
    <rect x="52.23" y="284.1" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 58.435px 290.4px;" id="elbpd0ab89qxl" class="animable"></rect>
    <rect x="53.32" y="284.1" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 58.975px 290.4px;" id="el0851pt6171ul" class="animable"></rect>
    <rect x="71.02" y="284.1" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 77.225px 290.4px;" id="elp4glg8y0dnm" class="animable"></rect>
    <rect x="72.11" y="284.1" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 77.765px 290.4px;" id="elvcnw32qlinr" class="animable"></rect>
    <rect x="89.81" y="284.1" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 96.015px 290.4px;" id="elf5d9zjgo59f" class="animable"></rect>
    <rect x="90.9" y="284.1" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 96.555px 290.4px;" id="ell9wdrhntgwj" class="animable"></rect>
    <rect x="52.23" y="302.65" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 58.435px 308.95px;" id="elh9mzddaksl" class="animable"></rect>
    <rect x="53.32" y="302.65" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 58.975px 308.95px;" id="eldvmf83c1u9p" class="animable"></rect>
    <rect x="71.02" y="302.65" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 77.225px 308.95px;" id="el6fp6iuph2ox" class="animable"></rect>
    <rect x="72.11" y="302.65" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 77.765px 308.95px;" id="el5r2y6f9yrd" class="animable"></rect>
    <rect x="89.81" y="302.65" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 96.015px 308.95px;" id="elvujhb8yuqwk" class="animable"></rect>
    <rect x="90.9" y="302.65" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 96.555px 308.95px;" id="el6iifyztdxw6" class="animable"></rect>
    <rect x="52.23" y="340.4" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 58.435px 346.7px;" id="el9ljmedvt2tl" class="animable"></rect>
    <rect x="53.32" y="340.4" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 58.975px 346.7px;" id="el78imh464knn" class="animable"></rect>
    <rect x="71.02" y="340.4" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 77.225px 346.7px;" id="elsike3y71yth" class="animable"></rect>
    <rect x="72.11" y="340.4" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 77.765px 346.7px;" id="eluy03lk9wvd" class="animable"></rect>
    <rect x="89.81" y="340.4" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 96.015px 346.7px;" id="elzfhx7sx29o8" class="animable"></rect>
    <rect x="90.9" y="340.4" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 96.555px 346.7px;" id="ellsofqghx1e" class="animable"></rect>
    <rect x="52.23" y="358.95" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 58.435px 365.25px;" id="elszz4jnfzjg" class="animable"></rect>
    <rect x="53.32" y="358.95" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 58.975px 365.25px;" id="elqkxzkp9iirl" class="animable"></rect>
    <rect x="71.02" y="358.95" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 77.225px 365.25px;" id="elnz1nwpzbht" class="animable"></rect>
    <rect x="72.11" y="358.95" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 77.765px 365.25px;" id="el8hl144rdces" class="animable"></rect>
    <rect x="89.81" y="358.95" width="12.41" height="12.6"
      style="fill: rgb(230, 230, 230); transform-origin: 96.015px 365.25px;" id="elfbgbvd8j6yl" class="animable"></rect>
    <rect x="90.9" y="358.95" width="11.31" height="12.6"
      style="fill: rgb(245, 245, 245); transform-origin: 96.555px 365.25px;" id="eled41x5cx3fv" class="animable"></rect>
    <rect x="45.96" y="271.59" width="80.74" height="1.17"
      style="fill: rgb(224, 224, 224); transform-origin: 86.33px 272.175px;" id="elbg87fq8lkg" class="animable"></rect>
    <rect x="45.96" y="329.65" width="80.74" height="1.17"
      style="fill: rgb(224, 224, 224); transform-origin: 86.33px 330.235px;" id="el6b71px9sgv3" class="animable"></rect>
    <rect x="364.43" y="189.16" width="79.77" height="194.11"
      style="fill: rgb(224, 224, 224); transform-origin: 404.315px 286.215px;" id="elfc3pfqyyad4" class="animable">
    </rect>
    <rect x="364.43" y="189.16" width="57.05" height="194.11"
      style="fill: rgb(240, 240, 240); transform-origin: 392.955px 286.215px;" id="elvl61k1bxh8" class="animable">
    </rect>
    <rect x="370.31" y="205.11" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 210.265px;" id="el6qdsfarspf4" class="animable">
    </rect>
    <rect x="371.72" y="205.11" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 210.265px;" id="elrf9aigky9cq" class="animable">
    </rect>
    <rect x="394.87" y="205.11" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 210.265px;" id="elbx0cn157dd" class="animable">
    </rect>
    <rect x="396.28" y="205.11" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 210.265px;" id="elmyb0vjpapr" class="animable"></rect>
    <rect x="370.31" y="224.51" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 229.665px;" id="elqbc1t0iiell" class="animable">
    </rect>
    <rect x="371.72" y="224.51" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 229.665px;" id="eluvn9oegeck" class="animable"></rect>
    <rect x="394.87" y="224.51" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 229.665px;" id="elz48che6qh1d" class="animable">
    </rect>
    <rect x="396.28" y="224.51" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 229.665px;" id="el3hpseqe537i" class="animable">
    </rect>
    <rect x="370.31" y="249.3" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 254.455px;" id="eldbxfob2fbsm" class="animable">
    </rect>
    <rect x="371.72" y="249.3" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 254.455px;" id="el3b1bnvhsz3s" class="animable">
    </rect>
    <rect x="394.87" y="249.3" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 254.455px;" id="el5tl71bx2a34" class="animable">
    </rect>
    <rect x="396.28" y="249.3" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 254.455px;" id="el6mmwmve129m" class="animable">
    </rect>
    <rect x="370.31" y="263.55" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 268.705px;" id="elknr7hgolr5" class="animable">
    </rect>
    <rect x="371.72" y="263.55" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 268.705px;" id="elci8k3r6hp8" class="animable"></rect>
    <rect x="394.87" y="263.55" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 268.705px;" id="elile1xeqrme" class="animable">
    </rect>
    <rect x="396.28" y="263.55" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 268.705px;" id="elsrmof7h0ao8" class="animable">
    </rect>
    <rect x="370.31" y="286.96" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 292.115px;" id="elbvitceibk1s" class="animable">
    </rect>
    <rect x="371.72" y="286.96" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 292.115px;" id="el8qjob87el8g" class="animable">
    </rect>
    <rect x="394.87" y="286.96" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 292.115px;" id="elqcy82kp54qo" class="animable">
    </rect>
    <rect x="396.28" y="286.96" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 292.115px;" id="eltu9n5g8i5" class="animable"></rect>
    <rect x="370.31" y="301.2" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 306.355px;" id="elkcwuio187hb" class="animable">
    </rect>
    <rect x="371.72" y="301.2" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 306.355px;" id="el8o17eiafm23" class="animable">
    </rect>
    <rect x="394.87" y="301.2" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 306.355px;" id="el3hu5a5lei6a" class="animable">
    </rect>
    <rect x="396.28" y="301.2" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 306.355px;" id="el2iior71guax" class="animable">
    </rect>
    <rect x="370.31" y="326.68" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 331.835px;" id="eloavh5p5qxtq" class="animable">
    </rect>
    <rect x="371.72" y="326.68" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 331.835px;" id="elfkibh657qn4" class="animable">
    </rect>
    <rect x="394.87" y="326.68" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 331.835px;" id="elu0fw4ofomyh" class="animable">
    </rect>
    <rect x="396.28" y="326.68" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 331.835px;" id="elpfbdr70azq" class="animable"></rect>
    <rect x="370.31" y="345.49" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 380.925px 350.645px;" id="elyhth491j9b" class="animable">
    </rect>
    <rect x="371.72" y="345.49" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 381.63px 350.645px;" id="eljzkvlc1x11m" class="animable">
    </rect>
    <rect x="394.87" y="345.49" width="21.23" height="10.31"
      style="fill: rgb(230, 230, 230); transform-origin: 405.485px 350.645px;" id="el74h1jvjn5nd" class="animable">
    </rect>
    <rect x="396.28" y="345.49" width="19.82" height="10.31"
      style="fill: rgb(245, 245, 245); transform-origin: 406.19px 350.645px;" id="ele1xspwscj5f" class="animable">
    </rect>
    <g id="el4jzg6tp4g4h">
      <rect x="364.43" y="241.17" width="79.77" height="1.77"
        style="opacity: 0.05; transform-origin: 404.315px 242.055px;" class="animable" id="el2fbf80g7pbj"></rect>
    </g>
    <g id="eltr0znwfulr">
      <rect x="364.43" y="279.52" width="79.77" height="1.77"
        style="opacity: 0.05; transform-origin: 404.315px 280.405px;" class="animable" id="elztw8e0e4h77"></rect>
    </g>
    <g id="el6bjodvr5xbj">
      <rect x="364.43" y="318.21" width="79.77" height="1.77"
        style="opacity: 0.05; transform-origin: 404.315px 319.095px;" class="animable" id="el2ljpc76oowi"></rect>
    </g>
    <rect x="360.67" y="183.42" width="83.53" height="6.2"
      style="fill: rgb(224, 224, 224); transform-origin: 402.435px 186.52px;" id="elr728yzmolqe" class="animable">
    </rect>
    <g id="elb5odib4n6ov">
      <rect x="425.11" y="183.42" width="19.09" height="6.2"
        style="opacity: 0.05; transform-origin: 434.655px 186.52px;" class="animable" id="elrdr3je675fm"></rect>
    </g>
    <rect x="160.64" y="145.58" width="90.18" height="234.38"
      style="fill: rgb(230, 230, 230); transform-origin: 205.73px 262.77px;" id="eleo0d2qsnu39" class="animable"></rect>
    <rect x="127.26" y="145.58" width="90.19" height="234.38"
      style="fill: rgb(240, 240, 240); transform-origin: 172.355px 262.77px;" id="el3cal9x6fhm5" class="animable">
    </rect>
    <rect x="135.13" y="161.49" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 167.46px;" id="elhb6b4716wq" class="animable"></rect>
    <rect x="138.02" y="161.49" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 167.46px;" id="elabbl78m7eaa" class="animable"></rect>
    <rect x="135.13" y="181.59" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 187.56px;" id="elmktwnhma3z7" class="animable">
    </rect>
    <rect x="138.02" y="181.59" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 187.56px;" id="elrce4qy8q9ip" class="animable"></rect>
    <rect x="135.13" y="202.47" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 208.44px;" id="elm26bgtt1ss" class="animable"></rect>
    <rect x="138.02" y="202.47" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 208.44px;" id="elvvo34l6bag" class="animable"></rect>
    <rect x="135.13" y="223.36" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 229.33px;" id="elo1zgeg7j9i9" class="animable">
    </rect>
    <rect x="138.02" y="223.36" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 229.33px;" id="els3muuhd6k4j" class="animable"></rect>
    <rect x="135.13" y="244.24" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 250.21px;" id="elt3812yl51dm" class="animable">
    </rect>
    <rect x="138.02" y="244.24" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 250.21px;" id="elbtf54e7m3z" class="animable"></rect>
    <rect x="135.13" y="265.13" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 271.1px;" id="elhty8jazyaki" class="animable"></rect>
    <rect x="138.02" y="265.13" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 271.1px;" id="eln959sjmewak" class="animable"></rect>
    <rect x="135.13" y="288.15" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 294.12px;" id="elcvwk7afl63a" class="animable">
    </rect>
    <rect x="138.02" y="288.15" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 294.12px;" id="elubkn0ks7q1j" class="animable"></rect>
    <rect x="135.13" y="311.16" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 317.13px;" id="elp85p4ot7ag" class="animable"></rect>
    <rect x="138.02" y="311.16" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 317.13px;" id="el1cg1weq4spx" class="animable"></rect>
    <rect x="135.13" y="334.18" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 340.15px;" id="eljiah72nn9p" class="animable"></rect>
    <rect x="138.02" y="334.18" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 340.15px;" id="el8gopx28j0l3" class="animable"></rect>
    <rect x="135.13" y="357.2" width="74.45" height="11.94"
      style="fill: rgb(230, 230, 230); transform-origin: 172.355px 363.17px;" id="elzt9jt8i1yk" class="animable"></rect>
    <rect x="138.02" y="357.2" width="71.56" height="11.94"
      style="fill: rgb(245, 245, 245); transform-origin: 173.8px 363.17px;" id="elmp2s8188yr" class="animable"></rect>
    <rect x="124.71" y="143.22" width="128.46" height="5.72"
      style="fill: rgb(245, 245, 245); transform-origin: 188.94px 146.08px;" id="elwitb5msvzga" class="animable"></rect>
    <rect x="124.71" y="132.9" width="128.46" height="5.72"
      style="fill: rgb(245, 245, 245); transform-origin: 188.94px 135.76px;" id="ele868hh7yps" class="animable"></rect>
    <rect x="127.26" y="137.5" width="121.64" height="5.72"
      style="fill: rgb(240, 240, 240); transform-origin: 188.08px 140.36px;" id="elv8xnf5vqvh" class="animable"></rect>
    <rect x="225.7" y="161.49" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 167.46px;" id="elk1ycxy9m9d" class="animable"></rect>
    <rect x="225.7" y="181.14" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 187.11px;" id="elmlv3fojkdx9" class="animable">
    </rect>
    <rect x="225.7" y="200.79" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 206.76px;" id="elp1rv9rc21lp" class="animable">
    </rect>
    <rect x="225.7" y="223.35" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 229.32px;" id="el7dc9opg2blp" class="animable">
    </rect>
    <rect x="225.7" y="244.24" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 250.21px;" id="elqrdo1jycmfl" class="animable">
    </rect>
    <rect x="225.7" y="265.13" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 271.1px;" id="elk9p5n3r56j" class="animable"></rect>
    <rect x="225.7" y="288.14" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 294.11px;" id="elma987v3s4co" class="animable">
    </rect>
    <rect x="225.7" y="311.16" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 317.13px;" id="eltmmfhhlvmx" class="animable"></rect>
    <rect x="225.7" y="334.18" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 340.15px;" id="elzef3ec1p9q" class="animable"></rect>
    <rect x="225.7" y="357.2" width="25.13" height="11.94"
      style="fill: rgb(224, 224, 224); transform-origin: 238.265px 363.17px;" id="elpm5h9dhynac" class="animable">
    </rect>
    <rect x="356.83" y="227.49" width="9.92" height="15.52"
      style="fill: rgb(224, 224, 224); transform-origin: 361.79px 235.25px;" id="eliv0booymq9" class="animable"></rect>
    <rect x="356.83" y="252.89" width="9.92" height="15.52"
      style="fill: rgb(224, 224, 224); transform-origin: 361.79px 260.65px;" id="eluaisfe9z2c" class="animable"></rect>
    <rect x="356.83" y="277.58" width="9.92" height="15.52"
      style="fill: rgb(224, 224, 224); transform-origin: 361.79px 285.34px;" id="eldw55pp7sv25" class="animable"></rect>
    <rect x="356.83" y="303.68" width="9.92" height="15.52"
      style="fill: rgb(224, 224, 224); transform-origin: 361.79px 311.44px;" id="eluujuesnavm" class="animable"></rect>
    <rect x="356.83" y="329.08" width="9.92" height="15.52"
      style="fill: rgb(224, 224, 224); transform-origin: 361.79px 336.84px;" id="el6if7zbjxfv4" class="animable"></rect>
    <rect x="262.36" y="211.89" width="104.39" height="158.76"
      style="fill: rgb(230, 230, 230); transform-origin: 314.555px 291.27px;" id="elb3t9d4e7cd8" class="animable">
    </rect>
    <rect x="262.37" y="211.89" width="86.75" height="158.76"
      style="fill: rgb(240, 240, 240); transform-origin: 305.745px 291.27px;" id="elt6444ak4q8" class="animable"></rect>
    <rect x="270.17" y="227.5" width="70.54" height="15.52"
      style="fill: rgb(235, 235, 235); transform-origin: 305.44px 235.26px;" id="elg06alk69yw" class="animable"></rect>
    <rect x="270.17" y="252.89" width="70.54" height="15.52"
      style="fill: rgb(235, 235, 235); transform-origin: 305.44px 260.65px;" id="el777xmc1x3yp" class="animable"></rect>
    <rect x="270.17" y="278.29" width="70.54" height="15.52"
      style="fill: rgb(235, 235, 235); transform-origin: 305.44px 286.05px;" id="elk1bbbpnorw" class="animable"></rect>
    <rect x="270.17" y="303.68" width="70.54" height="15.52"
      style="fill: rgb(235, 235, 235); transform-origin: 305.44px 311.44px;" id="elmd7zn4qli1" class="animable"></rect>
    <rect x="270.17" y="329.08" width="70.54" height="15.52"
      style="fill: rgb(235, 235, 235); transform-origin: 305.44px 336.84px;" id="elngz0v45hh1d" class="animable"></rect>
    <rect x="272.77" y="227.5" width="67.94" height="15.52"
      style="fill: rgb(245, 245, 245); transform-origin: 306.74px 235.26px;" id="elt1orpdxknd" class="animable"></rect>
    <rect x="272.77" y="252.89" width="67.94" height="15.52"
      style="fill: rgb(245, 245, 245); transform-origin: 306.74px 260.65px;" id="elxpelkpqfm9f" class="animable"></rect>
    <rect x="272.77" y="278.29" width="67.94" height="15.52"
      style="fill: rgb(245, 245, 245); transform-origin: 306.74px 286.05px;" id="elhs53dy6k1d7" class="animable"></rect>
    <rect x="272.77" y="303.68" width="67.94" height="15.52"
      style="fill: rgb(245, 245, 245); transform-origin: 306.74px 311.44px;" id="elaibrkywmn2d" class="animable"></rect>
    <rect x="272.77" y="329.08" width="67.94" height="15.52"
      style="fill: rgb(245, 245, 245); transform-origin: 306.74px 336.84px;" id="elbkwthpa30p7" class="animable"></rect>
  </g>
  <g id="freepik--Shape--inject-64" class="animable" style="transform-origin: 250px 416.24px;">
    <ellipse id="freepik--shape--inject-64" cx="250" cy="416.24" rx="193.89" ry="11.32"
      style="fill: rgb(245, 245, 245); transform-origin: 250px 416.24px;" class="animable"></ellipse>
  </g>
  <g id="freepik--Route--inject-64" class="animable animator-hidden" style="transform-origin: 78.985px 416.24px;">
    <line x1="139.73" y1="416.24" x2="135.73" y2="416.24"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 137.73px 416.24px;"
      id="el942tmp1k7a6" class="animable"></line>
    <line x1="128.17" y1="416.24" x2="26.02" y2="416.24"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; stroke-dasharray: 7.56611, 7.56611; transform-origin: 77.095px 416.24px;"
      id="elo6jl1kokfd" class="animable"></line>
    <line x1="22.24" y1="416.24" x2="18.24" y2="416.24"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 20.24px 416.24px;"
      id="elsepr5owzf6k" class="animable"></line>
  </g>
  <g id="freepik--Character--inject-64" class="animable" style="transform-origin: 249.979px 270.292px;">
    <g id="elv4h6dpb2kdq">
      <rect x="263.05" y="177.83" width="115.4" height="83.31"
        style="fill: #407BFF; transform-origin: 320.75px 219.485px; transform: rotate(-0.95deg);" class="animable"
        id="el8yp5vvegwe2"></rect>
    </g>
    <g id="elb7a9yoh30m5">
      <rect x="262.94" y="178.2" width="69.77" height="83.31"
        style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 297.825px 219.855px; transform: rotate(-0.95deg);"
        class="animable" id="elfe4maq68req"></rect>
    </g>
    <g id="elz05oow7ug39">
      <rect x="321.81" y="184.55" width="20.09" height="5.6"
        style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 331.855px 187.35px; transform: rotate(-1.14576deg);"
        class="animable" id="el8fdfxjg3jc"></rect>
    </g>
    <g id="eli0c4ja44fw">
      <rect x="323.08" y="248.18" width="20.09" height="5.6"
        style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 333.125px 250.98px; transform: rotate(-0.95deg);"
        class="animable" id="elx5lgnt5d37"></rect>
    </g>
    <ellipse cx="356.52" cy="216.85" rx="11.79" ry="13.34"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-miterlimit: 10; transform-origin: 356.52px 216.85px;"
      id="elipy2xb9bpti" class="animable"></ellipse>
    <line x1="357.01" y1="205.54" x2="357.01" y2="207.78"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 357.01px 206.66px;"
      id="elbjy5o78cptr" class="animable"></line>
    <line x1="357.52" y1="226.06" x2="357.52" y2="228.3"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 357.52px 227.18px;"
      id="elssiaijfndbj" class="animable"></line>
    <line x1="346.58" y1="216.41" x2="348.83" y2="216.41"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 347.705px 216.41px;"
      id="el9trsafk3qi" class="animable"></line>
    <line x1="364.64" y1="216.42" x2="366.89" y2="216.42"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 365.765px 216.42px;"
      id="eliyo2f59tsc" class="animable"></line>
    <line x1="357" y1="216.41" x2="357.01" y2="209.51"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 357.005px 212.96px;"
      id="el68oas0e35f" class="animable"></line>
    <line x1="357.44" y1="216.86" x2="362.32" y2="221.74"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 359.88px 219.3px;"
      id="ell84dzhkfcp" class="animable"></line>
    <path d="M358.09,216.42a1.09,1.09,0,1,1-1.09-1.09A1.08,1.08,0,0,1,358.09,216.42Z"
      style="fill: rgb(255, 255, 255); transform-origin: 357px 216.42px;" id="el4jzsmwj7eya" class="animable"></path>
    <g id="elyw82bifucj">
      <ellipse cx="356.52" cy="216.85" rx="15.61" ry="16.87"
        style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 356.52px 216.85px;" class="animable"
        id="elzfhqnp5gkz"></ellipse>
    </g>
    <path
      d="M226.88,363.15c1.74,1.33,36.67-2.43,27.64-64.4,0,0,16.57,4.21,18.35,3.93,0,0,24.31,37.27,6.61,55.73S235.4,378,235.4,378Z"
      style="fill: #407BFF; transform-origin: 256.408px 338.375px;" id="elkpwrdjygs" class="animable"></path>
    <g id="el3dn7p9o2zri">
      <path
        d="M226.88,363.15c1.74,1.33,36.67-2.43,27.64-64.4,0,0,16.57,4.21,18.35,3.93,0,0,24.31,37.27,6.61,55.73S235.4,378,235.4,378Z"
        style="opacity: 0.3; transform-origin: 256.408px 338.375px;" class="animable" id="elgtbzihao16h"></path>
    </g>
    <path
      d="M265.59,308.34c.09,0-9.76.19-13.06-3.91S247,293,253,288s41.23-26.6,57.82-28.3,34.35,9,34.35,9l-21.72,16.06-46.77,11.32Z"
      style="fill: rgb(38, 50, 56); transform-origin: 297.209px 283.929px;" id="elq6zg84sggdk" class="animable"></path>
    <polygon points="233.74 353.39 234.65 362.35 245.41 361.44 243.79 352.23 233.74 353.39"
      style="fill: rgb(255, 195, 189); transform-origin: 239.575px 357.29px;" id="elbhi07ral72t" class="animable">
    </polygon>
    <path d="M231.65,361.7l15.61-.22,1.11,7.88s-14.62.2-20.63-.6-5.07-3.93-5.07-3.93S229.71,365,231.65,361.7Z"
      style="fill: rgb(38, 50, 56); transform-origin: 235.493px 365.431px;" id="elsiejr04uti" class="animable"></path>
    <g id="elrvoiky6c75h">
      <ellipse cx="332.76" cy="380.8" rx="41.69" ry="35.29"
        style="fill: rgb(38, 50, 56); transform-origin: 332.76px 380.8px; transform: rotate(-84.19deg);"
        class="animable" id="elvdzp07i786g"></ellipse>
    </g>
    <path
      d="M308.24,253.29s1.62,37.17-20,38.23-44.85-1.31-44.85-1.31L250.2,357l-20.27,1.25s-14.55-56.66-16.84-70.9-.3-24.31,14.73-30.63S308.24,253.29,308.24,253.29Z"
      style="fill: #407BFF; transform-origin: 260.28px 305.44px;" id="eldgu0yl4p1qf" class="animable"></path>
    <path d="M187.25,371.22,214.18,293l36-39.74s-31.42.12-46.52,8.8S188,281.17,185.23,293.58s-13.88,71.15-13.88,71.15Z"
      style="fill: #407BFF; transform-origin: 210.765px 312.24px;" id="el46vxnqfgk8x" class="animable"></path>
    <g id="elsp7rzpyllt">
      <path
        d="M308.24,253.29s-33.46-1.49-58,0h0s-31.42.12-46.52,8.8S188,281.17,185.23,293.58s-13.88,71.15-13.88,71.15l15.9,6.49L214.18,293h0c4.53,21.68,15.75,65.21,15.75,65.21L250.2,357l-6.82-66.75s23.22,2.37,44.85,1.31S308.24,253.29,308.24,253.29Z"
        style="opacity: 0.7; transform-origin: 239.809px 311.924px;" class="animable" id="el8jkxbdst6yx"></path>
    </g>
    <path
      d="M253,193l-12.43,1.51s-2.16,22.85-4.17,27.29c0,0-38.23,5.25-53.89,4.8l-7.95,5.4-.28,9.39,7.79.23,2.51-5.95s5.26-.23,6.48-3.19c0,0,40.62,9.81,55.58-1.39C246.66,231.1,255.68,217.85,253,193Z"
      style="fill: rgb(255, 195, 189); transform-origin: 213.889px 217.31px;" id="elyrwfywpq78e" class="animable">
    </path>
    <path d="M288.62,131.81s3.89,5,2.41,9.33a7.57,7.57,0,0,1-5.49,5s-.73,6.93-4.82,7.13S278,138,278,138Z"
      style="fill: rgb(38, 50, 56); transform-origin: 284.573px 142.541px;" id="elrngop3nmbo" class="animable"></path>
    <path
      d="M257.13,253.46l-2.73-44.67-16.94-11.26S243,178,253.6,174.69s33.72-10.9,47.74-7.13,12.41,20.31,10,32.58l-2.7.64s-.42,48.35-.44,52.51Z"
      style="fill: rgb(38, 50, 56); transform-origin: 275.2px 209.997px;" id="eltj4mzctarjp" class="animable"></path>
    <path
      d="M287.61,170.32c-5.55,5.37-16.87,8.57-20,5.9s.82-5.12,3.76-8.14c1.59-1.66,1.54-4.77,1.17-7.25a25.53,25.53,0,0,0-.86-3.69c-10.39-.57-14.7-5.43-15.13-14S260,124.62,260,124.62l7.34-6.26s10,1.5,18,7c4.76,3.24,3.35,9.62,1.38,14.33a38.31,38.31,0,0,1-3,5.77C282.6,157.19,287.61,170.32,287.61,170.32Z"
      style="fill: rgb(255, 195, 189); transform-origin: 272.535px 147.77px;" id="eltonsn9yrny" class="animable"></path>
    <path d="M262.33,145.5s4,.19,5.75-2.29"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 265.205px 144.357px;"
      id="el4whkt0fk84t" class="animable"></path>
    <path d="M268.7,135.38c0,.76-.46,1.36-1,1.35s-.93-.64-.91-1.4.46-1.36,1-1.35S268.73,134.62,268.7,135.38Z"
      style="fill: rgb(38, 50, 56); transform-origin: 267.745px 135.355px;" id="el6p9mfvkj9ll" class="animable"></path>
    <path d="M259.58,135.62c0,.76-.46,1.36-1,1.35s-.93-.64-.91-1.4.46-1.36,1-1.35S259.61,134.87,259.58,135.62Z"
      style="fill: rgb(38, 50, 56); transform-origin: 258.625px 135.595px;" id="elzqjoddtkvme" class="animable"></path>
    <path d="M262.75,141.83l-.41-6.13-2.93,5.82A2.92,2.92,0,0,0,262.75,141.83Z"
      style="fill: rgb(237, 132, 126); transform-origin: 261.08px 138.957px;" id="elw4siyh8e2" class="animable"></path>
    <path d="M268.22,130.58a3.36,3.36,0,0,1,3.94,1.83"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 270.19px 131.435px;"
      id="elyz3zurzvids" class="animable"></path>
    <path d="M256.76,131.71s1.84-2,4-1.49"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 258.76px 130.923px;"
      id="eltm1t1caqa1k" class="animable"></path>
    <path
      d="M286.79,139.69h0c-11.44.41-12.86-12.56-12.86-12.56-5.66,3-13.89-2.5-13.89-2.5l7.34-6.28s10,1.52,18,7C290.17,128.6,288.76,135,286.79,139.69Z"
      style="fill: rgb(38, 50, 56); transform-origin: 274.33px 129.025px;" id="elclaqotkd19s" class="animable"></path>
    <path
      d="M256.36,128.43s-5.92-3.94-3.31-6.78,18.6,2.56,30.55,9.18c0,0-27.37-18.31-34.81-12.52-6.15,4.79,7.49,11.44,7.08,11.43Z"
      style="fill: #407BFF; transform-origin: 265.397px 124.004px;" id="eln07k1s9u8b" class="animable"></path>
    <path
      d="M289.75,127.09a17.13,17.13,0,0,1-1.16,6.06l-26.88-13a14.3,14.3,0,0,1,3.94-3.18,16.7,16.7,0,0,1,5.12-1.9,18.84,18.84,0,0,1,5.79-.2C286.39,116,289.8,123.56,289.75,127.09Z"
      style="fill: #407BFF; transform-origin: 275.73px 123.942px;" id="el7wz3b2l4tgd" class="animable"></path>
    <path
      d="M228,246.66c-1.75,1.83-24.38-2.86-28.33-.76-1.67.88-2.4,4-4.08,7.42h0c-2.29,4.65-6.36,9.75-17,10.09-18.48.59-16.25-18.16-17.71-24.25,0,0-17.5-3.19-18.42-6.26s.21-3.53.21-3.53,21.59,4,25.77-1.46a9.16,9.16,0,0,1,2.8-1.87,0,0,0,0,0,0,0,20.32,20.32,0,0,1,7.6-1.71c7.61-.4,16.89,1.74,19.49,6.56,5.28,9.83,27.92,7.62,30,10.2S229.75,244.83,228,246.66Z"
      style="fill: #407BFF; transform-origin: 185.862px 243.853px;" id="elqvxeocx87i" class="animable"></path>
    <path d="M170.71,259.47s-30.36,42-5.36,106.65"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 163.093px 312.795px;"
      id="el4zac73ry1np" class="animable"></path>
    <path
      d="M210.78,281.29h0s0,.08,0,.24,0,.25,0,.43v.1a80,80,0,0,1-2,12c-1.38,5.83-3.21,11.38-4.72,15.84-6.4,18.95-5.66,39.37-5.66,39.37-2.12-2.64-6-4.52-10.85-5.84-17.66-4.85-47.64-2.25-47.64-2.25-5.88-20.63-3.78-52.92,2.07-71s22.07-14,22.07-14,17.12.5,19.27.27a42.1,42.1,0,0,1,11.83.24C208.37,259.11,211.55,269.56,210.78,281.29Z"
      style="fill: #407BFF; transform-origin: 173.649px 302.528px;" id="elspkr9rcuwzh" class="animable"></path>
    <path
      d="M342.59,360c-30,27.08-87.91,29.92-131,26.92l-24.14-43.43-9.78-17.59,31.42-42.35,1.66-2.23s0,.08,0,.24,0,.25,0,.43v.1c-.43,7.55-3.21,68.14,19,83l.18.13A19.26,19.26,0,0,0,240,368.3c40.75,1.18,39.61-52,29.25-63.39l70.71-14.21S353.92,325.85,342.59,360Z"
      style="fill: #407BFF; transform-origin: 262.347px 334.596px;" id="el2f9rys5kzr6" class="animable"></path>
    <path
      d="M263.94,293.38s-6.63,5.5-3.21,12.88,36.39-6.95,49.37-9.56,28-5.16,34.46-6.49,5.9-19.86.62-21.5-57,7.5-65.58,12.52A87.06,87.06,0,0,0,263.94,293.38Z"
      style="fill: rgb(38, 50, 56); transform-origin: 304.511px 288.433px;" id="ela6lwecl3c4j" class="animable"></path>
    <path
      d="M280.54,144.27a4.1,4.1,0,0,1-.39-5.09c1.6-2.4,4.83-6.21,7.93-3.36,4.34,4-3.07,11.27-6.14,9.51A6.25,6.25,0,0,1,280.54,144.27Z"
      style="fill: rgb(255, 195, 189); transform-origin: 284.427px 140.203px;" id="elz1vwuz0881i" class="animable">
    </path>
    <g id="el44a1a087oaa">
      <path
        d="M256.36,128.43s-5.92-3.94-3.31-6.78c1.16-1.26,4.63-1,9.32.34a65.7,65.7,0,0,1,8.84,3.07c4.12,1.79,8.5,4.08,12.39,5.77,0,0-27.37-18.31-34.81-12.52-6.15,4.79,6.64,11,7.09,11.42l.22-.81Z"
        style="opacity: 0.2; transform-origin: 265.36px 124.004px;" class="animable" id="elyigtrmqudiq"></path>
    </g>
    <path d="M259.17,126.72s-6,1-1.86,7.46Z" style="fill: rgb(38, 50, 56); transform-origin: 257.512px 130.45px;"
      id="ellptrberdlk" class="animable"></path>
    <path d="M264.92,122.78s-9.05-3.18-11.57-1.38.12,5.23,3,7l2.31.3,1.36-4.1,3.52-.43Z"
      style="fill: rgb(38, 50, 56); transform-origin: 258.629px 124.774px;" id="elhs50i5wmqhn" class="animable"></path>
    <g id="elzhzqwbf822">
      <path d="M277.25,117.89l-2.17,3.21L265.65,117a16.7,16.7,0,0,1,5.12-1.9Z"
        style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 271.45px 118.1px;" class="animable"
        id="elrfhe1g9uyg"></path>
    </g>
    <g id="elr7nhmzn7138">
      <path d="M275.15,156.26a16,16,0,0,1-2.65,4.57,25.53,25.53,0,0,0-.86-3.69A10.34,10.34,0,0,0,275.15,156.26Z"
        style="opacity: 0.1; transform-origin: 273.395px 158.545px;" class="animable" id="elf0ok7b3vlf8"></path>
    </g>
    <path
      d="M185.5,244.2c-.4,6.93-3.4,12.6-7.08,14h0a4.77,4.77,0,0,1-2,.31c-4.54-.26-7.84-7.07-7.38-15.2s4.52-14.51,9.06-14.25c1.85.1,3.48,1.29,4.75,3.2h0C184.72,235,185.77,239.38,185.5,244.2Z"
      style="fill: rgb(245, 245, 245); transform-origin: 177.27px 243.785px;" id="elxnil2lrsmv" class="animable"></path>
    <path
      d="M185.5,244.2c-.4,6.93-3.4,12.6-7.08,14h0c-4.2-.24-7.25-6.53-6.82-14s4.17-13.4,8.37-13.16a4.87,4.87,0,0,1,2.91,1.25h0C184.72,235,185.77,239.38,185.5,244.2Z"
      style="fill: rgb(255, 255, 255); transform-origin: 178.551px 244.616px;" id="elhor7tuq0fg" class="animable">
    </path>
    <path
      d="M347.57,342.43c-7.73-5.43-37.64-10.76-37.64-10.76-18.17,0-34.12,16.35-36.32,38.06-1.8,17.66,6,33.69,18.57,41,0,0,1.05.44,2.81,1.13,6.66,2.63,23.56,9,33.55,10.4"
      style="fill: rgb(38, 50, 56); transform-origin: 310.461px 376.965px;" id="elliyhsciife" class="animable"></path>
    <g id="el6db051kcmnw">
      <ellipse cx="332.76" cy="380.8" rx="41.69" ry="35.29"
        style="opacity: 0.3; transform-origin: 332.76px 380.8px; transform: rotate(-84.19deg);" class="animable"
        id="elz4cn00z7xcm"></ellipse>
    </g>
    <g id="elz93qy3kxa7h">
      <ellipse cx="177.31" cy="379.47" rx="41.69" ry="35.29"
        style="fill: rgb(38, 50, 56); transform-origin: 177.31px 379.47px; transform: rotate(-84.19deg);"
        class="animable" id="elheum2egg2ia"></ellipse>
    </g>
    <path
      d="M198.88,345.64,181,420.71a30.1,30.1,0,0,1-7.89.23l-.2,0c-3.43-.88-1.9.07-5.34-1.07-8.75-2.89-21.46-8-21.46-8-13.47-6.55-21.08-22.48-19.23-40.68,2.24-22.05,18.06-38.52,36.68-37.17,0,0,8.84,1.63,17.94,4,4,1,8,2.19,11.29,3.43h0A33.52,33.52,0,0,1,198.88,345.64Z"
      style="fill: rgb(38, 50, 56); transform-origin: 162.743px 377.52px;" id="el7xdwy0knuy6" class="animable"></path>
    <g id="el1o1gbikfvlw">
      <path
        d="M212.42,383c-2,19.75-15.37,35.08-31.44,37.67a30.1,30.1,0,0,1-7.89.23l-.2,0a30.24,30.24,0,0,1-10.5-3.13c-13.51-6.85-22.06-23.49-20.19-41.89,2.33-22.89,19.9-39.84,39.28-37.9h.05a30.24,30.24,0,0,1,11.24,3.43h0a33.52,33.52,0,0,1,6.09,4.2C208.43,353.93,214,367.89,212.42,383Z"
        style="opacity: 0.3; transform-origin: 177.315px 379.443px;" class="animable" id="eltqgysuyhguo"></path>
    </g>
    <path
      d="M207.55,356.7a18.33,18.33,0,0,0-2-2.63c-4.47-6.1-14.69-8.38-21.38-7.84a31.12,31.12,0,0,0-14.33,5.14c-4.88,3.16-8.37,8-13.21,11.32-9.18,6.34-23.11,6.16-32.33-.37a14.21,14.21,0,0,1-1.48-1.21,6.82,6.82,0,0,1-1.8-3.55,9,9,0,0,1,.37-5c2-5.62,8.67-10.56,12.88-13.57a56.43,56.43,0,0,1,29-10.21C193,326.73,205.68,352.12,207.55,356.7Z"
      style="fill: #407BFF; transform-origin: 164.188px 347.998px;" id="elqmorniemvap" class="animable"></path>
    <g id="elkmajnpy0zm">
      <path
        d="M205.49,357.5c-6.39-9.22-14.59-11.81-21.28-11.27a31.12,31.12,0,0,0-14.33,5.14c-4.88,3.16-8.37,8-13.21,11.32-9.18,6.34-23.11,6.16-32.33-.37a14.21,14.21,0,0,1-1.48-1.21,6.82,6.82,0,0,1-1.8-3.55,9,9,0,0,1,.37-5c3.09,3.57,12.09,12.17,24.28,8,15.2-5.25,18.91-20.85,35.16-20.71C193.78,340,201.73,350,205.49,357.5Z"
        style="opacity: 0.3; transform-origin: 163.178px 353.591px;" class="animable" id="eld6bipv4vdu8"></path>
    </g>
    <g id="el2wti9idg867">
      <g style="opacity: 0.3; transform-origin: 168.255px 334.253px;" class="animable" id="elim03646664">
        <path
          d="M138.62,336.24A42.29,42.29,0,0,1,153,329.65a47.71,47.71,0,0,1,15.84-1.3,52.12,52.12,0,0,1,15.41,3.92l1.82.8,1.78.89c1.2.56,2.33,1.26,3.49,1.9s2.23,1.43,3.34,2.14,2.15,1.54,3.21,2.33A75.12,75.12,0,0,0,184,333a53.29,53.29,0,0,0-15.21-3.63,49.13,49.13,0,0,0-15.57,1,48.29,48.29,0,0,0-7.51,2.32A41.05,41.05,0,0,0,138.62,336.24Z"
          id="elmq6d6lfnyxp" class="animable" style="transform-origin: 168.255px 334.253px;"></path>
      </g>
    </g>
    <g id="elc3lki6m5dk8">
      <g style="opacity: 0.3; transform-origin: 169.712px 303.23px;" class="animable" id="elm01x7yvay7o">
        <path
          d="M171,329.09a55.12,55.12,0,0,1-2.23-12.82c-.33-4.33-.4-8.68-.31-13s.39-8.68.79-13l.73-6.46c.32-2.15.6-4.3,1-6.44l-.91,13-.36,6.48c-.06,2.16-.19,4.32-.2,6.48-.17,4.32-.09,8.64.07,13,.07,2.16.24,4.31.46,6.46A46.54,46.54,0,0,0,171,329.09Z"
          id="eldovz6psnoe" class="animable" style="transform-origin: 169.712px 303.23px;"></path>
      </g>
    </g>
    <g id="elpbja4bltm9">
      <path
        d="M229.7,365c-20.88-13.43-21.71-48.08-21-70.94.13-3.93.29-7.5.41-10.57a64.89,64.89,0,0,0,0-9.23C207,258.56,195,256.67,195,256.67h.05c13.29,2.44,16.47,12.89,15.7,24.62h0s0,.08,0,.24,0,.25,0,.43v.1C210.3,289.61,207.52,350.2,229.7,365Z"
        style="opacity: 0.3; transform-origin: 212.35px 310.835px;" class="animable" id="elt1g54nmaxag"></path>
    </g>
    <g id="elvmsa8jhdmyq">
      <path
        d="M228,246.66c-1.75,1.83-24.38-2.86-28.33-.76-1.67.88-2.4,4-4.08,7.42h0a0,0,0,0,0,0,0c-4.72,7.5-11.36,8.68-11.77,8.74,7.92-6,10.13-20.44,6.2-29.57s-18-6.76-18.8-6.49a20.32,20.32,0,0,1,7.6-1.71c7.61-.4,16.89,1.74,19.49,6.56,5.28,9.83,27.92,7.62,30,10.2S229.75,244.83,228,246.66Z"
        style="opacity: 0.2; transform-origin: 200.416px 243.151px;" class="animable" id="elxxgr6ff2u2k"></path>
    </g>
    <path
      d="M210.28,237.93a13.87,13.87,0,0,0-1.57,8.31l19.17,2.16a10.44,10.44,0,0,0,2.43-8.34S211.32,238.15,210.28,237.93Z"
      style="fill: rgb(38, 50, 56); transform-origin: 219.513px 243.165px;" id="elyourm2l2st" class="animable"></path>
    <path
      d="M152.07,237.22s-1.91-4.06.38-6.63c-5.12-.46-8.29-1-11.59-1.48-.49,1.49-.73,2.92,1,5.19S152.07,237.22,152.07,237.22Z"
      style="fill: rgb(38, 50, 56); transform-origin: 146.476px 233.165px;" id="elmga6eb95yl8" class="animable"></path>
    <path d="M141.42,262.63c-3.32,5.41-6.16,14.07-6.41,27.9"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 138.215px 276.58px;"
      id="elcwmmeemez5" class="animable"></path>
    <path d="M152.35,253.71a13.85,13.85,0,0,0-6.43,3.47"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 149.135px 255.445px;"
      id="elrv9k3q2qfpi" class="animable"></path>
    <path
      d="M301.48,201.58l-12.62,1.54s-2.2,23.19-4.24,27.7c0,0-38.81,5.34-54.72,4.87l-8.07,5.48-.28,9.54,7.91.23,2.55-6s5.34-.23,6.57-3.24c0,0,41.24,10,56.43-1.4C295,240.26,304.17,226.81,301.48,201.58Z"
      style="fill: rgb(255, 195, 189); transform-origin: 261.762px 226.26px;" id="el42dcuspga5k" class="animable">
    </path>
    <g id="el88ukvzlz95i">
      <path
        d="M198.72,380c-.36,12.58-8.6,22.54-18.41,22.26a14,14,0,0,1-3.88-.67c-7.86-2.5-13.52-11.77-13.21-22.62.31-10.5,6.1-19.18,13.71-21.59a14.07,14.07,0,0,1,4.71-.67C191.45,357,199.09,367.46,198.72,380Z"
        style="fill: rgb(250, 250, 250); opacity: 0.2; transform-origin: 180.97px 379.485px;" class="animable"
        id="el58c5ip8j239"></path>
    </g>
    <path
      d="M189.69,380c-.31,10.74-6,19.57-13.26,21.62-7.86-2.5-13.52-11.77-13.21-22.62.31-10.5,6.1-19.18,13.71-21.59C184.49,359.46,190,368.88,189.69,380Z"
      style="fill: rgb(38, 50, 56); transform-origin: 176.455px 379.515px;" id="el652652o4mnq" class="animable"></path>
    <g id="elu7ghivg6v4">
      <path
        d="M352.8,381c-.37,12.58-8.61,22.55-18.41,22.26a13.61,13.61,0,0,1-3.88-.67c-7.87-2.5-13.53-11.76-13.21-22.62.3-10.49,6.09-19.18,13.7-21.59a14.07,14.07,0,0,1,4.71-.67C345.52,358,353.16,368.44,352.8,381Z"
        style="fill: rgb(250, 250, 250); opacity: 0.2; transform-origin: 335.05px 380.485px;" class="animable"
        id="el6m3m8g791ea"></path>
    </g>
    <path
      d="M343.77,381c-.32,10.73-6,19.56-13.26,21.61-7.87-2.5-13.53-11.76-13.21-22.62.3-10.49,6.09-19.18,13.7-21.59C338.57,360.45,344.09,369.87,343.77,381Z"
      style="fill: rgb(38, 50, 56); transform-origin: 330.535px 380.505px;" id="elxozf2fu1ixa" class="animable"></path>
    <path
      d="M368.26,347.86c.17,12.37-18.66,20.56-50.17,25.72a79.49,79.49,0,0,1-25.7.3c-14-2.31-20.84-8.23-20.84-8.23s3.56-42.75,38.79-48.17c24-3.68,42.06,6,51.22,16.43C365.87,338.81,368.21,343.9,368.26,347.86Z"
      style="fill: #407BFF; transform-origin: 319.906px 345.732px;" id="elw3ueawyf9v" class="animable"></path>
    <g id="elhvzy1ispqx">
      <path
        d="M368.26,347.86c.17,12.37-18.66,20.56-50.17,25.72a79.49,79.49,0,0,1-25.7.3c1.51-11.61,8.29-42.06,37.76-45.22,13.37-1.44,23.69,1.26,31.41,5.25C365.87,338.81,368.21,343.9,368.26,347.86Z"
        style="opacity: 0.3; transform-origin: 330.326px 351.524px;" class="animable" id="elr441rprlasi"></path>
    </g>
    <g id="elgws0eciuy7n">
      <g style="opacity: 0.3; transform-origin: 294.81px 341.2px;" class="animable" id="eljhj64ndn54">
        <path
          d="M273.59,365.84a68.86,68.86,0,0,1,4.13-17,61.52,61.52,0,0,1,8.44-15.44,46,46,0,0,1,6-6.47,38.25,38.25,0,0,1,7.17-5.16,41.75,41.75,0,0,1,16.7-5.21,50.75,50.75,0,0,0-8.46,2.18c-1.39.42-2.7,1.07-4,1.62a42.47,42.47,0,0,0-3.81,2.07c-1.26.72-2.42,1.59-3.61,2.4s-2.29,1.77-3.34,2.78A45.39,45.39,0,0,0,287,334a63.64,63.64,0,0,0-8.51,15.11,77.16,77.16,0,0,0-2.86,8.24A73.12,73.12,0,0,0,273.59,365.84Z"
          id="el9h43oez1p89" class="animable" style="transform-origin: 294.81px 341.2px;"></path>
      </g>
    </g>
    <polygon points="226.31 351.72 228.39 358.78 250.2 356.96 250.49 347.09 226.31 351.72"
      style="fill: #407BFF; transform-origin: 238.4px 352.935px;" id="el6s4wgwdvd0i" class="animable"></polygon>
  </g>
  <g id="freepik--speed-line--inject-64" class="animable" style="transform-origin: 394.865px 306.36px;">
    <line x1="356.16" y1="303.57" x2="404.15" y2="301.62"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 380.155px 302.595px;"
      id="el0i2k3pmmkl5" class="animable"></line>
    <line x1="420.01" y1="292.88" x2="427.14" y2="292.59"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 423.575px 292.735px;"
      id="el1atj319hakb" class="animable"></line>
    <line x1="379.16" y1="294.54" x2="414.54" y2="293.1"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 396.85px 293.82px;"
      id="el7zm5nl2vg37" class="animable"></line>
    <line x1="403.92" y1="319.38" x2="433.57" y2="318.18"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 418.745px 318.78px;"
      id="elzv3oehfer6q" class="animable"></line>
    <line x1="385.59" y1="320.13" x2="398.51" y2="319.6"
      style="fill: none; stroke: #407BFF; stroke-linecap: round; stroke-linejoin: round; transform-origin: 392.05px 319.865px;"
      id="elvhtwrs19dih" class="animable"></line>
  </g>
  <defs>
    <filter id="active" height="200%">
      <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
      <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
      <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
      <feMerge>
        <feMergeNode in="OUTLINE"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
    <filter id="hover" height="200%">
      <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
      <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
      <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
      <feMerge>
        <feMergeNode in="OUTLINE"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
      <feColorMatrix type="matrix"
        values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 ">
      </feColorMatrix>
    </filter>
  </defs>
</svg>
