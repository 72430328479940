import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';

import { DefaultComponent } from './components/default/default.component';

//pages
import { BikePageComponent} from './pages/bike-page/bike-page.component';

import { CmctrucksvgComponent } from './svg/cmctrucksvg/cmctrucksvg.component';



const routes: Routes = [
  //Home
  {path: '', component: DefaultComponent },

  //Pages
  {path: 'Bike', component: BikePageComponent},
  {path: 'test', component: CmctrucksvgComponent},


  //Mobile Upload Pages

  //all else
  { path: '**', component: DefaultComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
