import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaterialModule } from './ngmaterial.module';

import { StorageServiceModule, LOCAL_STORAGE } from "ngx-webstorage-service";

import { AppRootComponent } from './app.component';


//Componenents
import { DefaultComponent } from './components/default/default.component';
import { APICaller } from './components/api-functions';

//Pages

import { BikePageComponent } from './pages/bike-page/bike-page.component';


//Svg's
import { DeliveryTruckComponent } from './svg/delivery-truck/delivery-truck.component'
import { DeliveryBikeComponent } from './svg/delivery-truck/delivery-bike/delivery-bike.component'
import { CmctrucksvgComponent } from './svg/cmctrucksvg/cmctrucksvg.component'



@NgModule({
  declarations: [
    AppRootComponent,
    DefaultComponent,


    BikePageComponent,

    //svg's
    DeliveryTruckComponent,
    DeliveryBikeComponent,
    CmctrucksvgComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgMaterialModule,
    StorageServiceModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,



    //KEEP LAST:
    AppRoutingModule
  ],
  providers: [APICaller],

  bootstrap: [AppRootComponent]
})
export class AppModule { }
