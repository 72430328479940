<div Background style="overflow: hidden;">



    <div *ngIf="!showDisclaimer" PageHeadingDiv bg-mint>

        <div d4s_logo></div>

        <div PageHeadingTitleDiv fg-coral>
            <a PageHeadingTitleText>WORK FOR YOURSELF DELIVERING GOODS AND MAKE MONEY...</a>
            <a PageHeadingTitleSubText>Applying is fast and easy!</a>
        </div>

    </div>



    <mat-progress-bar mode="determinate" value="100" id="docStart"></mat-progress-bar>

    <div *ngIf="!showFaq && !showDisclaimer">



        <div style="margin-top:40px;">

            <div
                style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                <a CallToActionText>What is required to apply?</a>
            </div>

            <hr style="width:80%;">

            <div style="display:flex; flex-direction:column">

                <div ReqRow>

                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">how_to_reg</mat-icon>
                        <a ReqText>You must be 18 years or older</a>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">badge</mat-icon>
                        <a ReqText>You need a valid Code A motorcycle license</a>
                    </div>

                </div>


                <div ReqRow>

                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">fingerprint</mat-icon>
                        <a ReqText>You must have a clear criminal history and consent to a crim check</a>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">history_edu</mat-icon>
                        <a ReqText>You must be permitted to work in South Africa, either via valid asylum or work
                            permit</a>
                    </div>

                </div>


                <div ReqRow>

                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">android</mat-icon>
                        <a ReqText style="color:red;">(Any phone except Huawei)</a>
                        <a ReqText>You need a recent android phone that is not older than 3 years</a>

                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">two_wheeler</mat-icon>
                        <a ReqText>You need a roadworthy motorcycle in presentable condition</a>
                    </div>

                </div>

            </div>


            <div
                style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px; margin-top:100px;">
                <a CallToActionText>Schedule & Payments</a>
            </div>
            <hr style="width:80%;">
            <div style="display:flex; flex-direction:column">

                <div ReqRow>

                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">routine</mat-icon>
                        <a ReqText>Flexible hours allow you to set your own pace and work routine</a>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div ReqCol>
                        <mat-icon color="primary" ReqIcon fontSet="material-symbols-outlined">payments</mat-icon>
                        <a ReqText>Payments are processed every week</a>
                    </div>

                </div>


            </div>

        </div>







        <div ReqRow id="applyStart" style="margin-top: 200px;">

            <div style="display:flex; flex-direction: row; justify-content:space-evenly; padding:20px;">
                <div
                    style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                    <a CallToActionText>More Questions?</a>
                    <a style="font-size: 1.2em; font-weight: 300; margin-top: 15px;">Our FAQ has you covered!</a>
                    <button apply_btn mat-raised-button color="primary" class="mat-elevation-z4"
                        style="border-radius:15px;" (click)="toggleFAQ();">
                        View FAQ!
                    </button>
                </div>
            </div>

            <div style="display:flex; flex-direction: row; justify-content:space-evenly; padding:20px;">
                <div
                    style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                    <a CallToActionText>Ready to apply?</a>
                    <a style="font-size: 1.2em; font-weight: 300; margin-top: 15px;">Awesome, get started!</a>
                    <button apply_btn mat-raised-button color="primary" class="mat-elevation-z4"
                        style="border-radius:15px;" (click)="toggleDisclaimer();">
                        Apply Now!
                    </button>
                </div>
            </div>


        </div>


    </div>

    <div *ngIf="showDisclaimer"
        style="display:  flex; flex-direction: column; justify-content: center; align-content: center; align-items: center;">



        <div style="margin-top:40px;">

            <div style="display:flex; flex-direction: column; justify-content:start; align-items: start; padding:20px; max-width: 600px;">
                <h1 style="color: red; text-decoration: underline;">Important Notice to All Applicants</h1>
                <h2>Attention:</h2>
                <p>Please be aware that neither CMC nor Pingo will ever request any form of payment from applicants for job placements.
                    If you are approached or receive any communication requesting any form of payment from anyone to secure a job
                    placement through our services do not comply.</p>
                <h2>What to Do:</h2>
                <p>If you receive such a request please report it immediately to:</p>
                <p>Telephone: <span style="color: red;">010 142 0182</span><br>
                    Email: <a href="mailto:fraud@cmcglobal.co.za">fraud@cmcglobal.co.za</a></p>
                <p>Your vigilance helps us maintain a safe and integrity friendly environment for all our applicants.
                    Thank you for your cooperation.</p>
                <p><strong>CMC Management</strong></p>
            </div>
            <hr>

            <div
                style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                <button apply_btn mat-raised-button color="primary" routerLink="../Bike" class="mat-elevation-z4"
                    style="border-radius:15px;">
                    Understood, Apply!
                </button>
                <button apply_btn mat-raised-button color="secondary" class="mat-elevation-z4"
                    style="border-radius:15px;" (click)="toggleDisclaimer();">
                    Cancel Application
                </button>
            </div>
        </div>



    </div>


    <div *ngIf="showFaq"
        style="display:  flex; flex-direction: column; justify-content: center; align-content: center; align-items: center;">



        <div style="margin-top:40px;" FAQAccordion>

            <div
                style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                <a CallToActionText>Frequently Asked Questions</a>
            </div>
            <hr>


            <mat-accordion>


                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            What is required to become a Pingo Driver Partner?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>



                    </mat-expansion-panel-header>
                    <ul style="display: flex; flex-direction: column; gap:10px; flex-wrap: wrap;">
                        <li><b>A valid driver’s license:</b> Have a valid drivers' license for the class of
                            motorcycle/vehicle which you will be driving </li>
                        <li><b>A valid SA Working permit or SA ID:</b> Be permitted to work in South Africa, either
                            through a South African ID or a valid work permit, must be valid for next 12-months </li>
                        <li><b>An Android mobile device - <a style="color:red;">(Any phone except Huawei)</a>:</b>
                            Drivers are required to have a fully functional Android mobile device (not older than 2019,
                            must be version 10 or newer) with a camera and at least 2GB memory (RAM). Should there be a
                            need, the driver has the option to procure a device from Pingo.</li>
                        <li><b>Data:</b> Roaming data which can work on any cell phone network.</li>
                        <li><b>A vehicle:</b> Your motorcycle/vehicle must be fully functional and roadworthy, free from
                            damage, dents, and scratches, with a valid license disc. If you are renting a motorcycle,
                            you need to ensure the rental company will accept that a bracket will need to be fitted to
                            the rented motorbike with the following dimensions: Length: 75cm x Width :30cm</li>
                        <li>Grade 12 or equivalent NQF level 4 (essential)</li>
                        <li>Older than 18 years </li>
                        <li><b>Verbal skills:</b> Proficient in English and numeracy and required to achieve a minimum
                            75% pass rate at NQF Level 4. Be able to communicate effectively and professionally in
                            English in face-to-face dealings with customers</li>
                        <li>No criminal record</li>
                        <li>Applicants need to provide proof of residence </li>
                        <li><b>Characteristics:</b> Punctual with attention to detail and ensure time frames are adhered
                            to with performance under pressure. Ability to work flexible hours. Meticulous about having
                            a neat appearance and maintaining personal hygiene. Portray a professional image as a brand
                            ambassador to the organization.</li>
                    </ul>
                </mat-expansion-panel>






                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            How are Pingo Driver Partners supported?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>


                    </mat-expansion-panel-header>
                    <ul style="display: flex; flex-direction: column; gap:10px; flex-wrap: wrap;">

                        <li><b>A Standard Load box</b><br>
                            We use standard branded load boxes to ensure quality delivery of our customer’s products.
                            They are big enough to accommodate & protect our customer’s orders.
                            The load box is branded as you are acting as a brand ambassador when delivering to our
                            customers. Part of your fee per order includes a fee for marketing.
                            The load box is also highly visible to ensure your safety.
                            We will fit the standard load box to your motorbike at our cost.</li>


                        <li><b>A mobile device</b><br>
                            Drivers are required to have a fully functional Android mobile device (not older than 2019,
                            must be version 10 or newer) with a camera and at least 2GB memory (RAM). Should there be a
                            need, the driver has the option to procure a device from Pingo</li>

                        <li><b>A mobile device mount</b><br>
                            Your motorbike needs to be fitted with a mobile device mount to make sure you can keep your
                            eyes on the road. Pingo will fit these device mounts as part of driver safety applications.
                            First device mount is free.</li>

                        <li><b>A branded uniform</b><br>
                            The uniform is designed to keep you comfortable & visible.
                            It will also be branded as you are representing our clients’ brands & acting as a brand
                            ambassador.
                            Drivers are responsible to ensure that uniforms are clean, free from smells, and undamaged
                            so that the Pingo brand is represented with pride and a professional service offered.
                            Drivers are issued with the following:
                            <ul>
                                <li>4 x Long sleeve T-shirts</li>
                                <li>Protective jacket</li>
                                <li>Rain jacket & pants</li>
                                <li>Helmet</li>
                            </ul>
                            The cost of the uniform and other items will be made known to you before it is issued.
                        </li>
                        <li><b>Insurance?</b><br>
                            Drivers will participate in the compulsory insurance products sourced by Pingo. This ensures
                            the driver is covered for accidents, theft, personal injury, 3rd party liability and death.
                            Further detail will be provided during the onboarding process.
                        </li>
                        <li><b>Mobile data? </b><br>
                            Drivers are expected to purchase roaming data bundles and are for drivers own account.
                        </li>
                        <li><b>Dedicated support team</b><br>
                            OTL’s & Control team<br>
                            Training & updates
                        </li>
                    </ul>
                </mat-expansion-panel>




                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            How do earnings & payments work?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>


                    </mat-expansion-panel-header>
                    <p>
                        Drivers will be delivering during scheduled hours throughout the week and are paid per valid
                        order delivered. <br>
                        The more efficiently a driver operates, the more deliveries are completed, and this increases
                        the income you can earn.<br>
                        You will be allocated to a certain cluster or zone to limit the distance traveling between
                        orders.

                    </p>
                    <ul style="display: flex; flex-direction: column; gap:10px; flex-wrap: wrap;">
                        <li>Earn a highly competitive rate per order delivered</li>
                        <li>Payments are processed weekly (Payments include trip payments and tips)</li>
                        <li>Drivers earn extra for additional drive-outs or extended drive distances</li>
                        <li>Opportunity to earn unlimited tips directly from customers. Drivers get paid the full tip
                            amount</li>
                    </ul>
                </mat-expansion-panel>


                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            Are Pingo Driver Partners incentivised for good and efficient service?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>


                    </mat-expansion-panel-header>
                    <p>
                        Customers have the option to pay you a tip to show appreciation for good and efficient
                        service.<br>
                        100% of tip amounts go directly to Drivers.<br>
                        Tips are paid once a week.<br>

                    </p>

                </mat-expansion-panel>

                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            Does Pingo offer a motorbike rental option?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>


                    </mat-expansion-panel-header>
                    <ul style="display: flex; flex-direction: column; gap:10px; flex-wrap: wrap;">

                        <li><b>Pingo support qualifying drivers with a range of rented motorbike options:</b><br>
                            The rental costs are deducted from driver earnings on a weekly basis and is automatically
                            covered under the compulsory insurance product.
                            Pingo’s motorbikes are fitted with run-flat tyres, LED lights, mobile device mounts and high
                            visibility load boxes for safety.</li>


                        <li> The driver is required to service their motorbike at regular intervals and Pingo will
                            contribute an amount of R550 per month whilst the vehicle is under the rental agreement.
                            Excess costs (above R550) would be for the driver’s account.</li>

                        <li>Drivers have access to a regional network of service centres to support their motorbike
                            servicing and parts replacement.</li>


                    </ul>
                </mat-expansion-panel>

                <!-- <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            Does Pingo offer a motorbike rental option?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>

                    </mat-expansion-panel-header>

                    <p style="display: flex; flex-direction: column; gap:10px;">
                        Pingo support qualifying drivers with a range of rented motorbike options, which will transfer to the driver’s ownership after an uninterrupted period of 14 to 24 months (depending on the motorbike issued). <br>
                        The rental costs are deducted from driver earnings on a weekly basis and is automatically covered under the compulsory insurance product. <br><br>

                        Pingo’s motorbikes are fitted with run-flat tyres, LED lights, mobile device mounts and high visibility load boxes for safety. <br><br>

                        The driver is required to service their motorbike at regular intervals and Pingo will contribute an amount of R550 per month whilst the vehicle is under the rental agreement. Excess costs (above R550) would be for the driver’s account.<br><br>

                        Drivers have access to a regional network of service centres to support their motorbike servicing and parts replacement. <br><br>
                    </p>

                </mat-expansion-panel> -->

                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            What happens if my application is successful?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>


                    </mat-expansion-panel-header>

                    <p style="display: flex; flex-direction: column; gap:10px;">
                        Successful applicants will receive training on how to work on the delivery application.<br>
                        Induction training is provided to ensure you represent our client’s brand with pride.<br>
                    </p>

                </mat-expansion-panel>

                <mat-expansion-panel style="margin-top: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#F8485E; font-weight: 600; font-size: 1em;">
                            How to apply to become a Pingo Driver Partner?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>


                    </mat-expansion-panel-header>

                    <p style="display: flex; flex-direction: column; gap:10px;">
                        Follow our quick and easy online application process to partner with Pingo.<br>
                    </p>
                    <!-- <button apply_btn2 mat-raised-button color="primary" class="mat-elevation-z4"
                        style="border-radius:15px;" (click)="toggleFAQ(); toggleDisclaimer();">
                        Apply To Be A Driver
                    </button> -->

                </mat-expansion-panel>

            </mat-accordion>

        </div>



        <div ReqRow>

            <div style="display:flex; flex-direction: row; justify-content:space-evenly; padding:20px;">
                <div
                    style="display:flex; flex-direction: column; justify-content:center; align-items: center; padding:20px;">
                    <button apply_btn mat-raised-button color="primary" class="mat-elevation-z4"
                        style="border-radius:15px;" (click)="toggleFAQ();">
                        Back
                    </button>
                </div>
            </div>


        </div>


    </div>






    <mat-progress-bar mode="determinate" value="100" style="margin-top:100px; margin-bottom: 0px;"></mat-progress-bar>




</div>