import { OverlayContainer } from '@angular/cdk/overlay';
import { LocalStorageService, SavedThemeObj } from './app.local-storage-manager';
import { Injectable } from '@angular/core';

const THEME_DARKNESS_SUFFIX = `-dark`;

@Injectable({
  providedIn: 'root',
})
export class ThemeManager {

  public themes: string[] = [
    'pink-bluegrey',
  ]

  isThemeDark = true
  activeTheme: string = 'pink-bluegrey';
  classBinding: string = 'pink-bluegrey';
  savedThemeObj: SavedThemeObj;
  static ThemeMan: ThemeManager;

  constructor(private overlayContainer: OverlayContainer, public parentClassBinding: string, private changeCallback: (cssClass: string) => any) {

    this.savedThemeObj = LocalStorageService.StorageManager.loadThemeFromClient();

    if (!this.savedThemeObj.name) {
      this.savedThemeObj.isDark = false;
      this.savedThemeObj.name =  this.SetTheme ='pink-bluegrey';
      this.isThemeDark = false;
      this.SetTheme = 'pink-bluegrey';
      this.RefreshTheme();
    }
    else {
      this.isThemeDark = this.savedThemeObj.isDark;
      this.SetTheme = this.savedThemeObj.name;
    }

  }

  public set SetDarkMode(isDark: boolean) {
    this.isThemeDark = isDark;
    this.RefreshTheme();
  }

  public set SetTheme(theme: string) {
    this.activeTheme = theme;
    this.RefreshTheme();
  }

  private RefreshTheme() {

    const cssClass = this.isThemeDark === true ? this.activeTheme + THEME_DARKNESS_SUFFIX : this.activeTheme;
    const classList = this.overlayContainer.getContainerElement().classList;

    if (classList.contains(this.parentClassBinding)) {
      classList.replace(this.parentClassBinding, cssClass);
    }
    else {
      classList.add(cssClass);
    }

    this.parentClassBinding = cssClass;
    this.changeCallback(cssClass);

    this.savedThemeObj.isDark = this.isThemeDark;
    this.savedThemeObj.name = this.activeTheme;
    LocalStorageService.StorageManager.saveCurrentThemeOnClient(this.savedThemeObj);
  }



}
