import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { APICaller, CallType } from '../../components/api-functions';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { HostListener } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';

interface uFile {
  file_type: number;
  file_name: string;
}

enum docType {
  ID = 7,
  Workers_Permit = 8,
  Passport = 9,
  Motor_Bike_License = 10,
  Vehicle_License = 11,
  Proof_of_Residence = 12,
  Bankstatement = 13,
  Motorbike_License_Disc = 14,
  MotorBike_Number_Plate = 15,
  Bike_Photo = 16,
  Cv = 17,
  Selfie = 18,
  Assylum = 19
}


@Component({
  selector: 'app-bike-page',
  templateUrl: './bike-page.component.html',
  styleUrls: ['./bike-page.component.scss'],
  providers: [DatePipe]
})
export class BikePageComponent implements OnInit {

  public provinces = ['Gauteng', 'Free State', 'Eastern Cape', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'Northern Cape', 'North West', 'Western Cape']
  public docType = docType;

  //add fields to keys array that need to be checked for that section to be marked completed
  validators = {
    "questions": {
      "keys": ["id_type", "has_own_bike", "has_bike_lic", "has_crim_record", "agree_popi", "agree_tos"],
      "is_valid": false
    },
    "personal_info": {
      "keys": ["name", "surname", "date_of_birth"],
      "is_valid": false
    },
    "contact_info": {
      "keys": ["phone"],
      "is_valid": false
    },
    "location_info": {
      "keys": ["street", "city", "province", "postal_code"],
      "is_valid": false
    }
  }

  alwaysValidateKeys = ["id", "selfie", "proof_of_address"]
  hasBikeValidateKeys = ["bike_numberplate", "bike_disc", "bike_photo"]
  fileValidators = {
    "id": {
      "file": null,
      "doc_type": -1,
      "keys": ["id_passport_number"],
      "is_valid": false
    },
    "work_permit": {
      "file": null,
      "doc_type": -1,
      "keys": ["work_permit_expiry_date"],
      "is_valid": false
    },
    "selfie": {
      "file": null,
      "doc_type": -1,
      "keys": [],
      "is_valid": false
    },
    "proof_of_address": {
      "file": null,
      "doc_type": -1,
      "keys": [],
      "is_valid": false
    },
    "bike_license": {
      "file": null,
      "doc_type": -1,
      "keys": ["bike_license_expiring_date"],
      "is_valid": false
    },
    "bike_numberplate": {
      "file": null,
      "doc_type": -1,
      "keys": [],
      "is_valid": false
    },
    "bike_disc": {
      "file": null,
      "doc_type": -1,
      "keys": [],
      "is_valid": false
    },
    "bike_photo": {
      "file": null,
      "doc_type": -1,
      "keys": [],
      "is_valid": false
    }
  }


  ApplicantData: ApplicantObj;
  ApplicationValid: boolean = false;
  showConfirmCancleApplication: boolean = false;

  keysWithFiles: string[] = [];
  totalFileCount: number = 0;
  isBusySaving: boolean = false;
  savedApplicantID: number = -1;

  curUploadText: string = "";
  curTotalProgressText: string = "";
  curUploadingFileName: String = "";
  curUploadingFileProgress: number = 0;
  totalFilesUploaded: number = 0;

  ApplicationSubmitted: boolean = false;

  uFiles: uFile[] = new Array<uFile>(7);

  is18OrOlder: boolean = false;
  isBikeLicenseValid: boolean = false;
  isIDValid: boolean = false;
  hasPreviousExperience: boolean = false;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
    history.pushState(null, null, document.URL);
  }


  constructor(private _snackBar: MatSnackBar,
    private route: Router, private apicaller: APICaller,
    private datePipe: DatePipe,


  ) {

    this.ApplicantData = this.getEmptyApplicant();
    history.pushState(null, null, document.URL);
  }


  ngOnInit(): void {

  }

  openSnackBar(title: string, msg: string) {
    this._snackBar.open(msg, title, {
      duration: 4000,
    });
  }


  inputChanged(section: string) {
    // console.log(this.ApplicantData)
    this.validateSection(section)
    this.ApplicationValid = this.finalValidator();
  }

  collectFile(e: any, type: docType, img: HTMLImageElement, input: HTMLInputElement, section: string) {
    // this.uploadPhoto(e.target.files[0], this.curImgSide)

    // this.compressImage(e.target.files[0], 1024, 768).then(compressed => {
    //   console.log(compressed);
    // });

    this.fileValidators[section].doc_type = type;
    // console.log("ASDFASDFAd", e.target.files[0]);
    this.fileValidators[section].file = e.target.files[0];
    img.src = URL.createObjectURL(<Blob>e.target.files[0]);
    input.value = "";

    this.validateUploadSection(section);
    this.ApplicationValid = this.finalValidator();
  }

  // compressImage(src, newX, newY) {
  //   return new Promise((res, rej) => {
  //     const img = new Image();
  //     img.src = URL.createObjectURL(<Blob>src);

  //     img.onload = () => {
  //       console.log(img.height, img.width);
  //       const elem = <HTMLCanvasElement>document.getElementById('canvas')
  //       elem.width = newX;
  //       elem.height = newY;
  //       const ctx = elem.getContext('2d');
  //       ctx.drawImage(img, 0, 0, newX, newY);
  //       const data = ctx.canvas.toDataURL();
  //       res(data);
  //     }
  //     img.onerror = error => rej(error);
  //   })
  // }


  uploadInputChanged(section: string) {
    // console.log(this.ApplicantData);
    // console.log(this.fileValidators);

    this.validateUploadSection(section);
  }


  validateUploadSection(section) {

    let isValid: boolean = true;

    this.fileValidators[section].keys.forEach(key => {
      if (this.validateValue(this.ApplicantData[key]) === false || this.fileValidators[section].file === null) {
        isValid = false;
      }

      if (key == "bike_license_expiring_date" && isValid) {

        //get date
        let date = new Date(this.ApplicantData[key]);
        //get todays date and add 30 days
        let today = new Date();
        today.setDate(today.getDate() + 30);
        //compare dates and set isValid to false if date is less than today + 30 days
        if (date < today) {
          isValid = false;
          this.isBikeLicenseValid = false;
        } else {
          this.isBikeLicenseValid = true;
        }
      }


    })

    this.fileValidators[section].is_valid = isValid;

  }






  validateSection(sectionName: string) {

    let isValid: boolean = true;

    this.validators[sectionName].keys.forEach(key => {

      if (this.validateValue(this.ApplicantData[key]) === false) {
        isValid = false;
      }

      if (key == "date_of_birth" && isValid) {
        //check that date of birth is not in the future and at least 18 years old
        let dob = new Date(this.ApplicantData[key]);
        let today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        let m = today.getMonth() - dob.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--;
        }
        if (age < 18) {
          isValid = false;
          this.is18OrOlder = false;
        } else {
          this.is18OrOlder = true;
        }

      }


    })

    this.validators[sectionName].is_valid = isValid;
  }


  //if more custom validation is required (id, mail, tel validation for instance)
  //this is the function to improve to do so right now it only checks null and empty strings
  //a second 'validator' param could be passed in from the validation object to direct 
  //how it should validate
  validateValue(val: any): boolean {
    return (val != "" && val != null && val != undefined);
  }

  //this validates the entire page for completion to decide if the "apply" button should show
  finalValidator(): boolean {

    let ApplicationValid: boolean = true;

    //normal inputs 
    Object.keys(this.validators).forEach(key => {
      if (!this.validators[key].is_valid) {
        ApplicationValid = false;
      }
    })

    //upload inputs 
    this.alwaysValidateKeys.forEach(key => {
      if (!this.fileValidators[key].is_valid) {
        ApplicationValid = false;
      }
    })

    //custom one off logic validations:
    if (this.ApplicantData['has_own_bike'] === "Yes") {
      this.hasBikeValidateKeys.forEach(key => {
        if (!this.fileValidators[key].is_valid) {
          ApplicationValid = false;
        }
      })
    }

    if (this.ApplicantData['id_type'] === "Passport") {
      if (!this.fileValidators['work_permit'].is_valid) {
        ApplicationValid = false;
      }
    }

    if (this.ApplicantData['has_bike_lic'] === "Yes") {
      if (!this.fileValidators['bike_license'].is_valid) {
        ApplicationValid = false;
      }
    }

    return ApplicationValid;
  }



  submitApplication() {

    let body = this.ApplicantData;
    body.date_of_birth = this.datePipe.transform(body.date_of_birth, 'YYYY-MM-dd');
    body.bike_license_expiring_date = this.datePipe.transform(body.bike_license_expiring_date, 'YYYY-MM-dd');
    body.work_permit_expiry_date = this.datePipe.transform(body.work_permit_expiry_date, 'YYYY-MM-dd');

    this.apicaller.CallEndPoint<any>('/api/input/add_applicant', body, CallType.POST, (response) => {

      this.savedApplicantID = response.id;
      if (this.savedApplicantID > 0) {
        this.isBusySaving = true;
        this.uploadCollectedFiles();
      }
    }, err => {
      this.openSnackBar('Save Failed', 'We had a problem saving, Check your internet.');
    });


  }



  uploadCollectedFiles() {

    //collect all the sections that have files
    Object.keys(this.fileValidators).forEach(key => {
      if (this.fileValidators[key].file) {
        this.keysWithFiles.push(key);
      }
    })

    this.totalFileCount = this.keysWithFiles.length;
    this.getNextFileKeyIfExists();

  }


  getNextFileKeyIfExists() {

    if (this.keysWithFiles.length > 0) {

      let nextKey = this.keysWithFiles.pop();

      this.curUploadText = "Now uploading " + nextKey.replace(/_/g, " ") + " - " + this.fileValidators[nextKey].file.name;
      this.curTotalProgressText = `Uploading ${this.totalFilesUploaded + 1} of ${this.totalFileCount} files`

      this.uploadFile(this.fileValidators[nextKey].file, this.savedApplicantID, this.fileValidators[nextKey].doc_type);
    } else {
      this.ApplicationSubmitted = true;
      this.openSnackBar('Success!', 'All Done! Thanks for your Application!');
    }

  }

  uploadFile(file: any, ApplicantID: number, docType: docType) {

    const form: FormData = new FormData();

    form.set('id', file)
    form.append('applicant', ApplicantID.toString())
    form.append('documentype', docType.toFixed(0))


    this.apicaller.uploadFile('/api/upload/upload_file', form).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.curUploadingFileProgress = Math.round(event.loaded / event.total! * 100);
          //console.log("prog: ", this.curUploadingFileProgress);
          break;
        case HttpEventType.Response:

          // setTimeout(() => {
          if (this.isBusySaving) {
            this.totalFilesUploaded += 1;
            this.getNextFileKeyIfExists();

          }
        // }, 1500);

      }
    });


  }


  getEmptyApplicant(): ApplicantObj {
    return {
      "id_type": "South African ID",
      "status": 1,
      "has_own_bike": "",
      "has_bike_lic": "",
      "has_crim_record": "",
      "crim_check_consent": "",
      "agree_popi": "",
      "agree_tos": "",
      "name": "",
      "surname": "",
      "date_of_birth": "",
      "phone": "",
      "email": "",
      "street": "",
      "city": "",
      "province": "",
      "postal_code": "",
      "id_passport_number": "",
      "shoe_size": "",
      "shirt_size": "",
      "pants_size": "",
      //"occupation": "",
      //"vehicle_license_code": "",
      //"vehicle_license_expiring_date": "",
      //"pdp_expire_date": "",
      //"bike_license_code": "",
      "applicant_experience": "",
      "bike_license_expiring_date": "",
      "work_permit_expiry_date": ""
      // "in_date": ""
    }
  }



}


// Generated by https://quicktype.io

export interface ApplicantObj {
  id?: number;
  id_type: string;
  status: number;
  has_own_bike: string;
  has_bike_lic: string;
  has_crim_record: string;
  crim_check_consent: string;
  agree_popi: string;
  agree_tos: string;
  name: string;
  surname: string;
  date_of_birth: string;
  phone: string;
  email: string;
  street: string;
  city: string;
  province: string;
  postal_code: string;
  id_passport_number: string;
  shoe_size: string;
  shirt_size: string;
  pants_size: string;
  //occupation: string;
  //vehicle_license_code: string;
  //vehicle_license_expiring_date: string;
  //pdp_expire_date: string;
  //bike_license_code: string;
  bike_license_expiring_date: string;
  work_permit_expiry_date: string;
  applicant_experience: string;
  // in_date: string;
}




