import { Component, ViewChild, HostBinding } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';


import { ThemeManager } from '../app/components/app.theme-manager';

import { LocalStorageService } from './components/app.local-storage-manager';
import { APICaller, CallType } from '../app/components/api-functions';


// Generated by https://quicktype.io

export interface ApplicantObj {
  id: number;
  documents: ApplicantDocumentObj[];
  id_type: string;
  status: number;
  has_own_bike: string;
  has_bike_lic: string;
  has_crim_record: string;
  crim_check_consent: string;
  agree_popi: string;
  agree_tos: string;
  name: string;
  surname: string;
  date_of_birth: string;
  phone: string;
  email: string;
  street: string;
  city: string;
  province: string;
  postal_code: string;
  id_passport_number: string;
  shoe_size: string;
  shirt_size: string;
  pants_size: string;
  occupation: string;
  vehicle_license_code: string;
  vehicle_license_expiring_date: string;
  pdp_expire_date: string;
  bike_license_code: string;
  bike_license_expiring_date: string;
  work_permit_expiry_date: string;
  applicant_note: string;
  applicant_experience:string;
  in_date: string;
}

// Generated by https://quicktype.io

export interface ApplicantDocumentObj {
  id: number;
  guid: string;
  path: string;
  name: string;
  file_type: string;
  applicant: number;
  document_type: number;
  in_date: string;
}

export interface ApplicantStatusObj {
  id: number;
  status: string;
  code: string;
  ordering: number;
}



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DatePipe]
})
export class AppRootComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  tableData: MatTableDataSource<ApplicantObj>;

  clickedRows = new Set<ApplicantObj>();
  displayedColumns: string[] = ['in_date', 'status', 'name', 'surname', 'id_number', 'phone', 'mail', 'province', 'documents'];
  selectedApplicant: ApplicantObj;




  title = "Drive4Self";
  themeManager: ThemeManager;
  width = screen.width;

  showAdmin: boolean = false;

  username = new FormControl('');
  password = new FormControl('');

  isAuthed = false;
  hidePass = true;
  canShowPass = false;

  isLoadingDoc = false;
  authedUser:string = "";


  applicantCount;
  fetchedApplicants: ApplicantObj[] = new Array();
  applicantStatuses: ApplicantStatusObj[] = [];
  currentIndex = 0;
  pagecount;
  currentPage = 1;

  panelOpenState;

  @HostBinding('class') activeThemeCssClass: string
  constructor(private overlayContainer: OverlayContainer,
    private localStorageService: LocalStorageService, private apicaller: APICaller,
    private toast: MatSnackBar, public datePipe: DatePipe) {
    this.themeManager = ThemeManager.ThemeMan;
    LocalStorageService.StorageManager = localStorageService;

    this.themeManager = new ThemeManager(overlayContainer, this.activeThemeCssClass, cssClass => {
      this.activeThemeCssClass = cssClass;
    });

    ThemeManager.ThemeMan = this.themeManager;

    this.fetchUser();

  }


  fetchApplicantStatuses() {

    this.apicaller.CallEndPoint<ApplicantStatusObj[]>('/api/applicant_statuses', {}, CallType.GET, (r) => {
      this.applicantStatuses = r;
      // console.log(this.applicantStatuses);
      this.fetchApplicants(); //prefetch applicants

    }, (e) => {
      this.toast.open('failed to get statuses', 'got it!', { duration: 3000 });
    });

  }




  fetchApplicants() {

    this.apicaller.CallEndPoint<ApplicantObj[]>('/api/get_all_applicants', {}, CallType.GET, (r) => {

      this.tableData = new MatTableDataSource<ApplicantObj>(r)
      this.tableData.sort = this.sort!;
      this.tableData.paginator = this.paginator!;
      
      console.log(this.tableData);
      this.tableData.filterPredicate = (data: ApplicantObj, filter: string) => data['id_passport_number'].toLowerCase().indexOf(filter) != -1;


      this.fetchApplicantCount();
    }, (e) => {
      this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
    });

  }


  getStatusText(status_id: number): string {
    return this.applicantStatuses.find(status => status?.id === status_id)?.status;
  }

  isZipping: boolean = false;


  downloadApplicant(applicant_id) {

    this.isZipping = true;

    this.apicaller.CallEndPoint<any>(`/api/download_applicant/${applicant_id}`, {}, CallType.GET, (r) => {
      let el: HTMLAnchorElement = <HTMLAnchorElement>document.getElementById('zipdownload');

      el.download = "https://drive4pingo.co.za/media/downloads/" + r.file;
      el.href = "https://drive4pingo.co.za/media/downloads/" + r.file;
      el.innerHTML = `Download ${r.file}`;
    }, (e) => {
      console.error(e);
      this.toast.open('error', 'got it!', { duration: 3000 });
    });
  }

  async setImgSrc(fileName: string) {

    this.isLoadingDoc = true;

    let el: HTMLIFrameElement = <HTMLIFrameElement>document.getElementById('photoViewer');

    let blob = await fetch(fileName).then(r => r.blob());

    this.isLoadingDoc = false;

    let url = window.URL.createObjectURL(blob);
    el.src = url;

  }


  applyFilter(filterValue: string) {
    console.log(this.applyFilter);

    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.tableData.filter = filterValue;
  }


  tableItemClicked(offer: ApplicantObj) {
    this.selectedApplicant = JSON.parse(JSON.stringify(offer));
    this.clickedRows.clear();
    this.clickedRows.add(offer);
  }



  updateApplicant() {
    this.apicaller.CallEndPoint<any>('/api/input/update_applicant/' + this.selectedApplicant.id, this.selectedApplicant, CallType.PUT, (response) => {

      console.log(response);
      this.toast.open('Applicant Saved!', 'got it!', { duration: 3000 });
    }, err => {
      this.toast.open('failed to save', 'got it!', { duration: 3000 });
    });
  }


  printDiv(divName) {

    var printContents = document.getElementById(divName)?.innerHTML;
    var originalContents = document.body.innerHTML;

    var wnd = window.open("#", "", "_blank");
    wnd?.resizeTo(800, 960);
    wnd?.document.write(printContents!);
    wnd?.print();
  }


  // printDiv(divName) {
  //   console.log(divName);
  //   var printContents = ' <button style="margin:5px" color="primary" mat-raised-button onclick="window.print()">print</button>' + document.getElementById(divName).innerHTML;
  //   var originalContents = document.body.innerHTML;

  //   var wnd = window.open("#", "", "_blank");
  //   wnd.resizeTo(800, 960);
  //   wnd.document.write(printContents);
  // }






  downloadApplicantFile() {

    //CREATE A CSV DOWNLOAD OF ALL APPLICANTS
    this.apicaller.CallEndPoint<{ "data": ApplicantObj[] }>('/api/get_all_applicants', {}, CallType.GET, (r) => {

      let applicants: ApplicantObj[];
      applicants = r.data;

      //make headers
      let data = "";
      let headers
      Object.keys(applicants[0]).forEach(key => {


        data = data.concat(key + ',')


      })

      data += '\n';

      applicants.forEach(applicant => {
        let newRow = "";

        Object.keys(applicant).forEach(key => {

          if (key == "documents") {
            newRow = newRow.concat(applicant.documents.length + ',')
          } else {

            newRow = newRow.concat(applicant[key] + ',')

          }

        })

        Object.keys(applicant.documents).forEach(dockey => {
          newRow = newRow.concat('=HYPERLINK("https://drive4pingo.co.za/media/uploads/' + applicant.documents[dockey].path + '"),')
        })

        data = data.concat(newRow + '\n');
        console.log(newRow);
      });


      let blob = new Blob([data], { type: 'document/csv' });
      let url = window.URL.createObjectURL(blob);
      let a = <HTMLAnchorElement>document.getElementById("hiddenDL");

      a.href = url;
      a.download = "All_Applicants_" + this.datePipe.transform(Date.now().toString(), "YYYY_MM_dd") + ".csv";
      a.click();
      window.URL.revokeObjectURL(url);

    }, (e) => {
      this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
    });


    // window.open(url);

  }




  ///AUTH SECTION===================
  autofilled(event): void {
    console.log(event);

    if (event.isAutofilled) {
      this.hidePass = true;
      this.canShowPass = false;
      return;
    }

    if (!event.isAutoFilled && String(this.password.value).length > 0) {
      this.canShowPass = false;
      this.hidePass = true;
    } else {
      this.canShowPass = true;
    }

    if (String(this.password.value).length === 0) {
      this.canShowPass = true;
    }
  }

  passChanged(): void {
    if (String(this.password.value).length === 0) {
      this.canShowPass = true;
    }
  }


  fetchUser() {

    this.apicaller.CallEndPoint<any>('/api/user', {}, CallType.GET, (r) => {


      //console.log(r);
      if (r.data.is_superuser) {
        this.isAuthed = true; //we already have a admin account we good
        this.showAdmin = true;
        this.authedUser = r.data.name;
        this.fetchApplicantStatuses();
      } else {
        this.phoneHome(); //we are unauthed fetch the public account
      }

    }, (e) => {
      // console.log(e.error.detail);
      //this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
    });
  }

  phoneHome() {
    let publicAcc = {
      "email": "public@cvapp.co.za",
      "password": "public"
    }

    this.apicaller.CallEndPoint<any>('/api/logout', {}, CallType.POST, (r) => {
      console.log("PHONED", r);
    });
  }

  LoginAuth(): void {
    const body = { email: this.username.value, password: this.password.value };

    this.apicaller.CallEndPoint<any>('/api/login', body, CallType.POST, (r) => {

      //console.log(r);
      this.authedUser = r.user;
      this.isAuthed = true;
      this.fetchApplicantStatuses();
      this.fetchApplicants();


    }, (e) => {
      // console.log(e.error.detail);
      this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
    });

  }


  Logout(): void {
    this.phoneHome();
    this.authedUser = "";
    this.isAuthed = false;
  }


  // saveAsBlob(data) {
  //   var blob = new Blob([data], { type: 'document/pdf' });
  //   var url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }


  fetchApplicantCount() {


    this.apicaller.CallEndPoint<any>('/api/get_applicant_count', {}, CallType.GET, (r) => {

      this.applicantCount = r.data.applicant_count

    }, (e) => {
      // console.log(e.error.detail);
      this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
    });


  }


  // fetchApplicantPage(fromIndex: number) {

  //   let nextPage = this.currentPage + fromIndex;



  //   if (nextPage < 1) {
  //     nextPage = 1;
  //   }
  //   if (nextPage > this.pagecount) {
  //     nextPage = this.pagecount;
  //   }

  //   this.apicaller.CallEndPoint<any>('/api/get_applicant_page/' + ((nextPage - 1) * 10), {}, CallType.GET, (r) => {


  //     console.log("applicants", r.data);
  //     this.fetchedApplicants = r.data;
  //     this.currentPage = nextPage;

  //   }, (e) => {
  //     // console.log(e.error.detail);
  //     this.toast.open('invalid credentials', 'got it!', { duration: 3000 });
  //   });

  // }


}



// Generated by https://quicktype.io


